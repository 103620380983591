import { SublistModal } from "@molecules/sublist-modal";
import { atom } from "recoil";

export const PricesDegModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "PricesDegModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const PricesDegModal = () => {
  return (
    <SublistModal
      atom={PricesDegModalAtom}
      schema={[
        { key: "qte", label: "Quantité", type: "number" },
        {
          key: "prixUnitTtc",
          label: "Prix unitaire TTC",
          type: "formatted",
          format: "price",
        },
        { key: "dateDebut", label: "Date début", type: "date" },
        { key: "dateFin", label: "Date fin ", type: "date" },

        {
          key: "prixTotal",
          label: "Prix total",
          type: "formatted",
          format: "price",
        },
      ]}
    />
  );
};
