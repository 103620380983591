import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info } from "@atoms/text";
import Env from "@config/environment";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { AccountPasswordModalAtom } from "./password-change-modal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";

export const AccountModalAtom = atom({
  key: "AccountModalAtom",
  default: false,
});

export const AccountModal = () => {
  const { t } = useTranslation();
  const { logout, user } = useAuth();
  const { current: currentShopLocation, save: saveLocation } =
    useShopLocations();
  const [accountModal, setAccountModal] = useRecoilState(AccountModalAtom);
  const setAccountPasswordModal = useSetRecoilState(AccountPasswordModalAtom);
  const navigate = useNavigate();

  return (
    <>
      <Modal open={accountModal} onClose={() => setAccountModal(false)}>
        <ModalContent title={[user?.prenom, user?.nom].map((a) => a).join(" ")}>
          <Info className="block">
            {t("account.introduction.content").split("$1")[0]}
            <b>
              {currentShopLocation?.libLieu
                ? currentShopLocation?.libLieu +
                  ` (${currentShopLocation?.codeLieu})`
                : t("account.introduction.nosite")}
            </b>{" "}
            {t("account.introduction.content").split("$1")[1].split("$2")[0]}
            <b>
              {currentShopLocation?.libProf
                ? `${currentShopLocation?.libProf} (${currentShopLocation?.codeProf})`
                : t("account.introduction.guest")}
            </b>
          </Info>
          <div className="flex flex-col">
            <Button
              className="block mt-4"
              onClick={() => {
                //saveLocation(null);
                navigate(ROUTES.Locations);
                setAccountModal(false);
              }}
            >
              {t("account.change_location")}
            </Button>

            <Button
              className="block mt-4"
              onClick={() => {
                setAccountPasswordModal({ open: true, forced: false, v: "" });
              }}
            >
              {t("account.changePassword")}
            </Button>

            <Button
              className="block mt-4"
              theme="danger"
              onClick={() => {
                saveLocation(null);
                setAccountModal(false);
                logout();
              }}
            >
              {t("account.logout")}
            </Button>
          </div>
          <br />
          <div className="text-center">
            <Info>
              srv:{user?.apiVersion} - clt:{Env.version}
            </Info>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
