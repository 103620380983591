export const ProductFilters = {
  all: [
    { key: "pertinence", text: "Pertinence" },
    { key: "designation", text: "Désignation" },
    { key: "dateParu", text: "Date de parution" },
    { key: "fourn_princ", text: "Fournisseur" },
    { key: "editeur", text: "Éditeur/Fabricant" },
  ],
  book: [
    { key: "pertinence", text: "Pertinence" },
    { key: "designation", text: "Désignation" },
    { key: "dateParu", text: "Date de parution" },
    { key: "fourn_princ", text: "Fournisseur" },
    { key: "editeur", text: "Éditeur" },
  ],
  paper: [
    { key: "pertinence", text: "Pertinence" },
    { key: "designation", text: "Désignation" },
    { key: "dateParu", text: "Date de parution" },
    { key: "fourn_princ", text: "Fournisseur" },
    // { key: "codeMarque", text: "Marque" },
    // { key: "codeFamille", text: "Famille" },
    // { key: "codeSousFamille", text: "Sous Famille" },
    // { key: "codeSegment", text: "Segment" },
    // { key: "codeEditeur", text: "Fabricant" },
  ],
  record: [
    { key: "pertinence", text: "Pertinence" },
    { key: "designation", text: "Désignation" },
    { key: "dateParu", text: "Date de parution" },
    { key: "fourn_princ", text: "Fournisseur" },
    // { key: "auteur", text: "Auteur" },
    // { key: "editeur", text: "Éditeur" },
    // { key: "codeGenre", text: "Genre" },
    // { key: "codeSupp", text: "Support" },
    //{ key: "codeInterprete", text: "Interprète" },
  ],
  press: [
    { key: "pertinence", text: "Pertinence" },
    { key: "designation", text: "Désignation" },
    { key: "dateParu", text: "Date de parution" },
    { key: "fourn_princ", text: "Fournisseur" },
    //{ key: "codeSousFamille", text: "Sous Famille" }
  ],
};

export const getFilters = (modeGestion: string) => {
  switch (modeGestion) {
    case "L":
      return ProductFilters.book;
    case "N":
      return ProductFilters.book;
    case "all":
      return ProductFilters.all;
    case "S":
      return ProductFilters.press;
    case "D":
      return ProductFilters.record;
    default:
      return ProductFilters.paper;
  }
};
