import { Modal, ModalContent } from "@atoms/modal/modal";
import { OrderHistory } from "@components/order/history";
import { atom, useRecoilState, useRecoilValue } from "recoil";

export const HistoryCommandModalAtom = atom({
  key: "HistoryCommandModalAtom",
  default: false,
});

export const NumCliHistoryModalAtom = atom({
  key: "NumCliHistoryModalAtom",
  default: "",
});
export const HistoryCommandModal = () => {
  const [historyCommandModal, setHistoryCommandModal] = useRecoilState(
    HistoryCommandModalAtom
  );
  const numCli = useRecoilValue(NumCliHistoryModalAtom);

  return (
    <Modal
      className="bg-red-400 sm:max-w-[900px]"
      open={historyCommandModal}
      onClose={() => setHistoryCommandModal(false)}
    >
      <ModalContent title={"Historique commandes"}>
        {historyCommandModal && <OrderHistory clientID={numCli} />}
      </ModalContent>
    </Modal>
  );
};
