import { orderBy } from "lodash";

export function doDivsOverlap(div1: any, div2: any) {
  if (!div1 || !div2) return false;

  const rect1 = div1.getBoundingClientRect();
  const rect2 = div2.getBoundingClientRect();

  // Calculer les points centraux de chaque div
  const div1CenterX = rect1.left + rect1.width * 0.7;
  const div1CenterY = rect1.top + rect1.height / 2;
  const div2CenterX = rect2.left + rect2.width * 0.7;
  const div2CenterY = rect2.top + rect2.height / 2;

  // Vérifier si les centres se superposent
  const isOverlappingX =
    Math.abs(div1CenterX - div2CenterX) <= rect1.width / 2 + rect2.width / 2;
  const isOverlappingY =
    Math.abs(div1CenterY - div2CenterY) <= rect1.height / 2 + rect2.height / 2;

  if (isOverlappingX && isOverlappingY) {
  }
  return isOverlappingX && isOverlappingY;
}

export function swapArrayElements<T>(
  arr: T[],
  index1: number,
  index2: number
): T[] {
  const newArr = [...arr];

  newArr.splice(index1, 1, arr[index2]);
  newArr.splice(index2, 1, arr[index1]);

  return newArr;
}

export function extractSubObjects<T extends object>(
  objects: T[],
  keys: string[]
): Partial<T>[] {
  return objects.map((obj) => {
    const subObject: Partial<T> = {};
    keys.forEach((key) => {
      if (key in obj) {
        // @ts-ignore: Ignoring the TypeScript error for indexing with a string
        subObject[key] = obj[key];
      }
    });
    return subObject;
  });
}

export function differenceInMinutes(
  timestamp1: number,
  timestamp2: number
): number {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Différence en millisecondes
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());

  // Convertir en minutes
  return diffInMs / (1000 * 60);
}

export function findDifferentKeys(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): string[] {
  let differences: string[] = [];

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  let allKeys = new Set([...keys1, ...keys2]);

  // Convertir le Set en Array pour l'itération
  let allKeysArray = Array.from(allKeys);

  for (let key of allKeysArray) {
    if (obj1[key] !== obj2[key]) {
      differences.push(key);
    }
  }

  return differences;
}

export function focusInput(queryString: string) {
  const inputElement = document.querySelector(queryString);
  if (inputElement instanceof HTMLInputElement) {
    //inputElement.focus();
    inputElement.select();
  }
}

export function isMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function sortByKey<T extends Record<string, any>>(
  list: T[],
  key: string,
  direction: "ASC" | "DESC"
): T[] {
  return orderBy(
    list,
    [(item) => +item[key]],
    [direction.toLowerCase() as "asc" | "desc"]
  );
}

export function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = (obj1 as any)[key];
    const val2 = (obj2 as any)[key];

    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!deepEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

export const parseTextToJSON = (text: string): Record<string, string> => {
  const lines = text.trim().split("\n");
  const result: Record<string, string> = {};

  lines.forEach((line) => {
    const [keyPart, valuePart] = line.split(": ");
    if (keyPart && valuePart) {
      const key = keyPart.trim();
      const value = valuePart.trim();
      result[key] = value;
    }
  });

  return result;
};

export const parseTextToDictionary = (text: string): Record<string, string> => {
  const lines = text.trim().split("\n");
  const dictionary: Record<string, string> = {};

  lines.forEach((line) => {
    const [keyPart, valuePart] = line.split(": ");
    if (keyPart && valuePart) {
      const key = keyPart.trim();
      const value = valuePart.trim();
      dictionary[key] = value;
    }
  });

  return dictionary;
};
