import { SublistModal } from "@molecules/sublist-modal";
import { atom } from "recoil";

export const CatalogueModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "CatalogueModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const CatalogueModal = () => {
  return (
    <SublistModal
      atom={CatalogueModalAtom}
      schema={[
        { key: "codeCatal", label: "Catalogue", type: "text" },
        { key: "libCatal", label: "Libellé", type: "text" },
        { key: "numPage", label: "Page", type: "text" },
        { key: "positPage", label: "Position sur la page", type: "text" },
        {
          key: "parVente",
          label: "Par vente",
          type: "formatted",
          format: "price",
        },
        { key: "indicParVenteUniq", label: "Par exclusif", type: "boolean" },
      ]}
    />
  );
};
