import { persistAtom, syncAtom } from "@features/utils/hooks/use-local-storage";
import { atom, atomFamily } from "recoil";
import { ProductsBasketType, ProductType } from "../types";
import { nanoid } from "nanoid";

export const ProductAtom = atomFamily<ProductType | null, string>({
  key: "ProductAtom",
  default: null,
});

// export const SearchProductsAtom = atom<
//   CommonSearchResultType<ProductTypeShort>
// >({
//   key: "SearchProductsAtom",
//   default: {
//     pageCount: 0,
//     totalRows: 0,
//     pageNumber: 0,
//     pageSize: 0,
//     items: [],
//   },
// });

export const SearchProductsAtom = persistAtom<any>(
  "user",
  syncAtom({
    key: "SearchProductsAtom",
    default: {
      pageCount: 0,
      totalRows: 0,
      pageNumber: 0,
      pageSize: 0,
      items: [],
    },
  })
);

export const SearchModeGestion = atom({
  key: "SearchModeGestion",
  default: "all",
});

//For persist TAB later
export const ActiveProductTab = persistAtom<any[]>(
  "user",
  syncAtom({
    key: "ProductFilterTabsAtom",
    default: [{ dispo: true }],
  })
);

export const ProductFiltersAtom = persistAtom<any>(
  "user",
  syncAtom({
    key: "ProductFiltersAtom",
    default: {
      all: [
        { key: "pertinence", text: "Pertinence" },
        { key: "designation", text: "Désignation" },
        { key: "prixPublic", text: "Prix" },
        { key: "dateParu", text: "Date" },
      ],
    },
  })
);

export const ProductsBasketsAtom = persistAtom<ProductsBasketType[]>(
  "user",
  syncAtom({
    key: "ProductsBasketsAtom",
    default: [
      {
        created_at: Date.now(),
        id: nanoid(),
        products: [],
        customer: {
          nomCli: "",
          prnomCli: "",
          addrMail: "",
          mobil: "",
          numCli: null,
        },
      } as ProductsBasketType,
    ],
  })
);

export const SelectedBasketId = persistAtom<string>(
  "user",
  syncAtom({
    key: "ProductBasketAtom",
    default: "",
  })
);

//Temp: will be factored later
export const FamilySearchSugg = atom<{
  famille: {
    current: { label: string; value: string };
    sugg: { label: string; value: string; codeRech: string }[];
  };
  sousFamille: {
    current: { label: string; value: string };
    sugg: { label: string; value: string; codeRech: string }[];
  };
  segment: {
    current: { label: string; value: string };
    sugg: { label: string; value: string; codeRech: string }[];
  };
}>({
  key: "FamilySearch",
  default: {
    famille: {
      current: { label: "", value: "" },
      sugg: [{ label: "", value: "", codeRech: "" }],
    },
    sousFamille: {
      current: { label: "", value: "" },
      sugg: [{ label: "", value: "", codeRech: "" }],
    },
    segment: {
      current: { label: "", value: "" },
      sugg: [{ label: "", value: "", codeRech: "" }],
    },
  },
});
