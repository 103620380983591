import { Page } from "@atoms/layout/page";
import { Base, Section, Title } from "@atoms/text";
import { useCallback, useState } from "react";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionCard, getLibel } from "@components/reception/reception-cards";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import {
  ReceptionSearchResult,
  ReceptionUnit,
} from "@features/reception/types";
import { InputWithSuggestionsReception } from "@components/reception/input-suggestion-reception";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { useSetRecoilState } from "recoil";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { useScan } from "@atoms/input-scan/use-scan";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Button } from "@atoms/button/button";
import { ReceptionInfoModalAtom } from "./receptions-modals/reception-info-modal";
import {
  FolderOpenIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { toDateISO } from "@features/utils/format/dates";
import { BookRender } from "@atoms/book";
import { XIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";
import { FaClockRotateLeft } from "react-icons/fa6";

export const ReceptionPage = () => {
  const [value, setValue] = useState("");
  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const [searchResult, setSearchResult] = useState<ReceptionSearchResult[]>([]);
  const [history, setHistory] = useState(false);

  const {
    mainSearch,
    loading,
    refreshAllOngoingReceipt,
    changeSelectedReception,
    getReceipt,
    deleteReceipt,
    allOngoingReceipt,
  } = useReception();

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "reception-all-grid-mode",
    {
      activated: false,
    }
  );

  const [currentOrder, setCurrentOrder] = usePersistedState<any>(
    "user",
    "reception-all-order",
    {
      orderBy: 0,
      orderDir: "DESC",
    }
  );

  const [numPage, setNumPage] = usePersistedState(
    "user",
    "reception-all-num-page",
    1
  );

  const navigate = useNavigate();
  const { scanOn } = useScan();
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);

  const handleSearch = useCallback(
    async (
      { page, perPage, order } = { page: 1, perPage: 25, order: "titre" }
    ) => {
      setSearchResult(
        await mainSearch({
          query: value,
          orderBy: order,
          orderDir: "ASC",
          additionalSorts: [{ orderBy: "dateCreat", orderDir: "DESC" }],
          pageNumber: page,
          pageSize: perPage,
        })
      );
    },
    [mainSearch, value]
  );

  const fournisseur: Column<ReceptionUnit> = {
    title: "Fournisseur",
    orderable: true,
    render: (el) => (
      <div className="flex gap-2">
        <div className="flex flex-col justify-center items-end w-6 h-6">
          <BookRender src="" productType={el.modeGest} />
        </div>
        <Base>{el.codeFourn}</Base>
      </div>
    ),
  };

  const numeroBL: Column<ReceptionUnit> = {
    title: "Num. BL",
    render: (el) => el.numBL,
  };

  const numero: Column<ReceptionUnit> = {
    title: "Num. reception",
    render: (el) => el.numRecep,
  };

  const avex: Column<ReceptionUnit> = {
    title: "Type reception",
    render: (el) => getLibel(el),
  };
  const nbColis: Column<ReceptionUnit> = {
    title: "Nombre de colis",
    render: (el) => (
      <Base className="lg:w-28 md:text-center lg:text-right">
        {el.nbrColis}
      </Base>
    ),
  };

  const dateRecep: Column<ReceptionUnit> = {
    orderable: true,
    title: "Date",
    render: (el) => toDateISO(el.dateRecep),
  };

  const infosModal: Column<ReceptionUnit> = {
    title: "Actions",
    render: (el) => (
      <div className="w-full flex justify-center gap-1">
        <Button
          size="sm"
          onClick={(event) => {
            changeSelectedReception(el);
            setReceptionInfoModal(true);
            event.stopPropagation();
          }}
          icon={({ className }) => (
            <InformationCircleIcon className={className} />
          )}
          data-tooltip="Informations sur la réception"
        />
        {!history && (
          <Button
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              changeSelectedReception(el);
              navigate(
                ROUTES.OngoingReception.replace(/:numRecep/, el.numRecep)
              );
              setConfirmModal({
                theme: "danger",
                icon: () => (
                  <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                ),
                open: true,
                title: "Supprimer la réception",
                message: "Voulez vous supprimer cette réception",
                onAccept: async () => {
                  if (el.qteRecu > 0)
                    toast.error(
                      "Cette réception ne peut être supprimée car une ou plusieurs ligne(s) ont déja été réceptionnée(s)"
                    );
                  else {
                    await deleteReceipt(el);
                  }
                },
              });
            }}
            theme="danger"
            icon={({ className }) => <XIcon className={className} />}
            data-tooltip="Supprimer la réception"
          />
        )}
      </div>
    ),
  };

  useControlledEffect(() => {
    handleSearch();
  }, [value]);

  return (
    <Page loading={loading}>
      <div className="w-full flex justify-between">
        <Title>Réception</Title>
        <Button
          disabled={loading}
          theme={history ? "outlined" : "primary"}
          onClick={() => {
            setHistory(!history);
          }}
          icon={({ className }) => {
            return history ? (
              <FolderOpenIcon className={className} />
            ) : (
              <FaClockRotateLeft className={className} />
            );
          }}
          data-tooltip={
            history
              ? "Voir récéptions en cours"
              : "Voir historique de réceptions"
          }
        >
          {history ? "En cours" : "Historique"}
        </Button>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-3/4 sm:w-1/3 my-2">
          {!history && (
            <InputWithSuggestionsReception
              options={searchResult}
              onChange={(e) => setValue(e.target.value)}
              inputMode={scanOn ? "none" : undefined}
              inputClassName="to-focus"
              autoFocus={true}
              value={value}
              placeholder="Chercher/Biper, une réception, une commande, un fournisseur..."
            />
          )}
        </div>
      </div>
      <div className="grow overflow-hidden">
        <Section>
          {history
            ? "Historique des réceptions"
            : "Liste des receptions en cours"}
        </Section>
        <TableGridSwitch
          key={`${history}`}
          data={allOngoingReceipt.items}
          gridMode={gridMode.activated}
          onChangeMode={(v) => setGridMode({ activated: v })}
          showPagination={"full"}
          cellClassName={() => "rounded-lg"}
          initialPagination={{
            perPage: 15,
            page: numPage,
            order: currentOrder.orderDir,
            orderBy: currentOrder.orderBy,
          }}
          onClick={async (receipt) => {
            const res = await getReceipt(receipt);
            if (res) {
              if (history) {
                changeSelectedReception(res);
                navigate(
                  ROUTES.OngoingReception.replace(
                    /:numRecep/,
                    receipt.numRecep
                  ),
                  { state: { history: true } }
                );
              }
              if (receipt.recepOuver) {
                setConfirmModal({
                  theme: "danger",
                  icon: () => (
                    <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                  ),
                  open: true,
                  title: "Réception en cours",
                  message:
                    "Cette réception est déjà en cours de traitement par: INFER. Souhaitez-vous continuer ?",
                  onAccept: async () => {
                    changeSelectedReception(res);
                    navigate(
                      ROUTES.OngoingReception.replace(
                        /:numRecep/,
                        receipt.numRecep
                      )
                    );
                  },
                });
              } else {
                changeSelectedReception(res);
                navigate(
                  ROUTES.OngoingReception.replace(/:numRecep/, receipt.numRecep)
                );
              }
            }
          }}
          onFetchExportData={async () => {
            return allOngoingReceipt.items;
          }}
          exportDefaultHeader={[
            "codeFourn",
            "indicFactFourn",
            "numRecep",
            "numAttenRecep",
            "nbrColis",
          ]}
          tableColumns={[
            fournisseur,
            numeroBL,
            numero,
            avex,
            nbColis,
            dateRecep,
            infosModal,
          ]}
          renderGrid={(receipt) => (
            <ReceptionCard item={receipt as ReceptionUnit} />
          )}
          total={allOngoingReceipt.pagination.totalRows}
          orderables={["Date", "Fournisseur"]}
          onRequestData={async ({ page, perPage, order, orderBy }) => {
            setCurrentOrder({
              orderBy: orderBy,
              orderDir: order || "DESC",
            });
            refreshAllOngoingReceipt(
              {
                pageNumber: page,
                pageSize: perPage,
                orderBy: orderBy === 0 ? "dateCreat" : "codeFourn",
                orderDir: order,
              },
              { trait: history }
            );
            setNumPage(page);
          }}
          gridClassName={
            "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          }
        />
      </div>
    </Page>
  );
};
