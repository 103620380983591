import { Button } from "@atoms/button/button";
import { Frame } from "@atoms/layout/frame";
import { Base, BaseBold, Subtitle, Title } from "@atoms/text";
import { Order } from "@features/customer/type";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/format/dates";

import {
  ArrowNarrowRightIcon,
  CheckIcon,
  PrinterIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderReview from "./order-product-list";
import { useSetRecoilState } from "recoil";
import { HistoryCommandModalAtom } from "@molecules/history-command-modal";
import Barcode from "react-barcode";
import { formatAmount } from "@features/utils/format/strings";
import { useShopLocations } from "@features/general/auth/state/use-store-location";

export const FinishedOrder = (props: { command: Order }) => {
  const navigate = useNavigate();
  const setHistoryCommandModal = useSetRecoilState(HistoryCommandModalAtom);
  const { current: currentShop } = useShopLocations();
  const { created } = useParams<{ created: string }>();
  const { current } = useBaskets();
  const { add } = useBasket(current || "");
  const location = useLocation();
  const from = location.state?.from;

  return (
    <div className="flex flex-col mx-6 print:p-0 print:m-0">
      <div className="h-full grow flex flex-col mt-6 md:flex-row md:justify-around print:hidden">
        <div className="h-full grow flex content-start justify-between mb-2">
          <Title>Commande client</Title>
          <div className="flex flex-col-reverse md:flex-row md:mt-0 md:gap-4">
            <Button
              className="w-auto mx-auto"
              onClick={() => {
                window.print();
              }}
              icon={(p) => <PrinterIcon {...p} />}
            >
              Imprimer
            </Button>

            <Button
              theme="danger"
              className="w-auto mx-auto"
              onClick={() => {
                const pattern = /^\/products\/basket\/([^/]+)\/client-order$/;
                if (from === ROUTES.Clients) {
                  navigate(from);
                } else if (created === "1") {
                  navigate(ROUTES.Products);
                } else if (pattern.test(from)) {
                  navigate(
                    ROUTES.BasketClientOrder.replace(
                      /:id/,
                      from.match(pattern)[1]
                    )
                  );
                  setHistoryCommandModal(true);
                }
              }}
              icon={(p) => <ArrowNarrowRightIcon {...p} />}
            >
              Retour
            </Button>
          </div>
        </div>
      </div>
      <div
        className={
          created === "1"
            ? "flex print:hidden mb-6 flex-start print:mb-1"
            : "hidden"
        }
      >
        <CheckIcon className="h-5 w-5 text-white bg-green-500 my-auto mr-4 rounded-full" />
        <BaseBold>Commande passée avec succès</BaseBold>
      </div>
      <Title>Récapitulatif commande #{props.command.numCde || ""}</Title>
      <Subtitle className="hidden print:block mb-4">
        Date de la commande:{" "}
        {formatTime(props.command.dateCde || "", "fr-FR", {
          keepTime: false,
          keepDate: true,
        })}
      </Subtitle>
      <Frame className="my-4 print:my-2">
        {/* <QRCodeCanvas
          className="float-right"
          value={JSON.stringify({
            commandId: props.command.numCde,
            type: "basket",
            content: props.command.items.map((a) => [a.product.id, a.qte]),
          })}
        /> */}
        <div className="float-right print:h-full">
          <Barcode value={props.command.eanCde || ""} height={50} width={2} />
        </div>
        <div className="flex flex-col gap-2">
          <Subtitle>
            {props.command.nomCli} {props.command.prnomCli}
          </Subtitle>
          <Subtitle>Numéro client: {props.command.numCli}</Subtitle>
          <Subtitle>{props.command.addrMail}</Subtitle>
          <Subtitle>
            {formatAmount(
              props.command.mtAcomp || 0,
              currentShop?.devisSymb,
              currentShop?.devisNbDecim
            )}{" "}
            d'acompte
          </Subtitle>
          {props.command.comm && props.command.comm.length > 0 && (
            <Frame className="w-full flex flex-col print:hidden">
              <BaseBold>Commentaire:</BaseBold>
              <Subtitle> {props.command.comm}</Subtitle>
            </Frame>
          )}
        </div>
      </Frame>
      {created !== "1" && (
        <div className="flex justify-end print:hidden">
          <Button
            theme="secondary"
            icon={(p) => <ShoppingCartIcon {...p} />}
            onClick={(e) => {
              props.command.items.map((el) => add(el.product, el.qte));
            }}
          >
            Tout ajouter
          </Button>
        </div>
      )}
      <div className="my-0 print:p-0 print:m-0 print:my-2">
        <OrderReview
          products={props.command.items}
          readonly={true}
          onChange={() => {}}
          enableBuy={created !== "1"}
        />
      </div>

      {props.command.comm && props.command.comm.length > 0 && (
        <Frame className="w-full flex flex-col print:w-full invisible print:visible ">
          <BaseBold>Commentaire:</BaseBold>
          <Subtitle> {props.command.comm}</Subtitle>
        </Frame>
      )}

      <Base className="hidden print:block mt-4">
        Date d'édition:{" "}
        {formatTime(Date.now(), "fr-FR", { keepTime: true, keepDate: true })}
      </Base>
    </div>
  );
};
