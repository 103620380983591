type Listener = (time: Date) => void;
let lastRequestTime: Date = new Date(0); // initialisé à une date par défaut
let listeners: Listener[] = [];

export const updateLastRequestTime = (): void => {
  lastRequestTime = new Date();
  listeners.forEach((listener) => listener(lastRequestTime));
};

export const subscribeToLastRequestTime = (
  listener: Listener
): (() => void) => {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
};

export const getLastRequestTime = (): Date => {
  return lastRequestTime;
};
