export const AuthJWT: {
  token: string | null;
  refresh: string | null;
  clientId: number | null;
  codeLieu: string | null;
  userId: string | null;
  tokenExpiryTime: string | null;
  empty: () => void;
} = {
  token: null,
  refresh: null,
  clientId: null,
  codeLieu: null,
  userId: null,
  tokenExpiryTime: null,
  empty: () => {
    AuthJWT.token = null;
    AuthJWT.refresh = null;
    AuthJWT.clientId = null;
    AuthJWT.codeLieu = null;
    AuthJWT.userId = null;
    AuthJWT.tokenExpiryTime = null;
  },
};

(window as any).AuthJWT = AuthJWT;
