import { Page } from "@atoms/layout/page";
import { Client, Order } from "@features/customer/type";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateOrder } from "../../../../components/order/create-order";

export const CommandePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [command, setCommand] = useState({
    client: {} as Client,
    order: {
      numCde: null,
      numCli: null,
      nomCli: "",
      prnomCli: "",
      mobil: "dd",
      addrMail: "customerID",
      updateCustomerForm: true,
      mtAcomp: 0,
      items: [],
    } as Order,
  });

  useEffect(() => {
    document.title = "Inférence - Validation cde cli";
  });

  useControlledEffect(() => {
    if (command.order.numCde) {
      navigate(
        ROUTES.Order.replace(/:id/, command.order.numCde)
          .replace(/:created/, "1")
          .replace(/:ts/, Date.now().toString()),
        { state: { from: location.pathname } }
      );
    }
  }, [command]);

  return (
    <>
      <Page>
        <CreateOrder onOrderCompleted={(order) => setCommand(order)} />
      </Page>
    </>
  );
};

export default CommandePage;
