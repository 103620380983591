import { useAuth } from "@features/general/auth/state/use-auth";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ShopLocationClient } from "../api-client/locations-api-client";
import { AuthJWT } from "../jwt";
import { ShopLocation } from "../types";
import { CurrentShopLocation, ShopLocationList } from "./store";

export const useShopLocations = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useShopLocation", false])
  );
  const [locationList, setLocationList] = useRecoilState(ShopLocationList);
  const [currentShopLocation, setCurrentLocation] =
    useRecoilState(CurrentShopLocation);

  const refreshLocationList = useCallback(async () => {
    if (!user?.id) return;
    setLoading(true);
    const result = await ShopLocationClient.getLocations(user?.id || "");
    setLocationList(result);
    setLoading(false);
  }, [setLocationList, setLoading, user?.id]);

  const saveCurrentLocation = async (location: ShopLocation | null) => {
    localStorage.setItem("user.location", JSON.stringify(location));
    setCurrentLocation(location);
  };

  useGlobalEffect(
    "putConnection",
    () => {
      if (currentShopLocation && user?.id) {
        AuthJWT.codeLieu = currentShopLocation.codeLieu;
        ShopLocationClient.putConnection(
          user?.id,
          currentShopLocation.codeLieu
        );
      }
    },
    [currentShopLocation, user?.id]
  );

  useGlobalEffect(
    "useShopLocations",
    () => {
      refreshLocationList();
    },
    []
  );

  return {
    loading,
    locations: locationList,
    current: currentShopLocation,
    save: saveCurrentLocation,
    refresh: refreshLocationList,
  };
};
