import { CommonPagination } from "@features/types";
import { SupplierOrder } from "../types";
import { atom } from "recoil";

export const SupplierOrderList = atom<{
  pagination: CommonPagination;
  items: SupplierOrder[];
}>({
  key: "SupplierOrderList",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as SupplierOrder[],
  },
});
