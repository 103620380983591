import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
} from "@zxing/library";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useScan } from "./use-scan";
import { BarcodeUtils } from "@features/utils/barcode";
import { setNativeValue } from "@features/utils/set-native-value";
import { useAutoFocus } from "@features/utils/auto-focus";

let lastScanDate = 0;
let scanCallbacks: ((text: string) => Promise<boolean>)[] = [];

const hints = new Map();
const formats = [
  BarcodeFormat.EAN_13,
  BarcodeFormat.EAN_8,
  BarcodeFormat.CODE_128,
  BarcodeFormat.MAXICODE,
  BarcodeFormat.QR_CODE,
];

hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

/**
 * Register a callback to be called when a barcode is detected
 * @param callback gets the scanned string and returns true if we must stop other callbacks
 */
export const registerScanCallback = (
  callback: (text: string) => Promise<boolean>
) => {
  scanCallbacks.push(callback);
};

export const unregisterScanCallback = (
  callback: (text: string) => Promise<boolean>
) => {
  scanCallbacks = scanCallbacks.filter((cb) => cb !== callback);
};

export const BarcodeScanner = () => {
  const [isEnvironment, setIsEnvironment] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const codeReader = new BrowserMultiFormatReader(hints);
  const { selectedInput } = useAutoFocus();

  const { scanOn, closeScan } = useScan();

  useControlledEffect(() => {
    (async () => {
      try {
        if (
          navigator.mediaDevices &&
          navigator.mediaDevices.getUserMedia &&
          videoRef.current &&
          scanOn
        ) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" },
          });

          if (stream)
            setIsEnvironment(
              "environment" ===
              stream?.getVideoTracks()[0].getSettings().facingMode ||
              stream?.getVideoTracks()[0].label.indexOf("back") !== -1
            );

          const videoElement = videoRef.current;

          if (videoElement) {
            // Vérification supplémentaire
            videoElement.srcObject = stream;

            // Démarrer le scan
            codeReader.decodeFromVideoDevice(
              null,
              videoElement,
              async (result) => {
                if (videoElement.srcObject === null) {
                  codeReader.reset();
                  return;
                }
                let focusedElement = selectedInput;
                if (!focusedElement) {
                  focusedElement = document.activeElement as HTMLInputElement;
                }
                focusedElement.focus();

                /*  //let focusedElement = document.activeElement as HTMLInputElement;
                if (!focusedElement || focusedElement?.tagName !== "INPUT") {
                  //focusedElement = getInputField();
                  focusedElement =
                    (document.querySelector(".prior") as HTMLInputElement) ||
                    (document.activeElement as HTMLInputElement);
                  focusedElement.focus();
                } */
                if (
                  result &&
                  focusedElement?.tagName === "INPUT" &&
                  lastScanDate + 1000 < Date.now()
                ) {
                  const text = BarcodeUtils.anyToEan13(result.getText());

                  //Try every callback and stop if it returns true
                  for (const callback of scanCallbacks) {
                    if (await callback(text)) return;
                  }

                  setNativeValue(focusedElement!, text);
                  playFlashSound();
                  lastScanDate = Date.now();
                  setTimeout(() => {
                    const event = new KeyboardEvent("keyup", {
                      key: "Enter",
                      code: "enter",
                      which: 13,
                      keyCode: 13,
                    });
                    document.dispatchEvent(event);
                  }, 100);
                  //Partie pour éteindre la camera
                  /* const vidEl = videoElement.srcObject as MediaStream;
                  const allTracks = vidEl.getTracks();
                  allTracks.forEach((track) => {
                    track.stop();
                  }); */
                }
              }
            );
          }
        }
      } catch (err) {
        toast.error("Impossible d'accéder à la caméra");
        console.error("Erreur d'accès à la caméra:", err);
        closeScan();
      }
    })();

    return () => {
      let focusedElement = document.activeElement as HTMLInputElement;
      focusedElement.blur();
      codeReader.reset();
    };
  }, [codeReader]);

  useControlledEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject as MediaStream;
        let tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      closeScan();
    };
  }, []);
  useControlledEffect(() => {
    return () => {
      if (!scanOn && videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject as MediaStream;
        let tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      let focusedElement = document.activeElement as HTMLInputElement;
      focusedElement.blur();
    };
  }, [scanOn]);

  return (
    scanOn && (
      <div
        style={{ zIndex: 999999, maxWidth: 200 }}
        className="bg-slate-500 fixed bottom-6 right-6 w-1/2 md:w-1/4 lg:w-1/4 rounded-lg shadow-lg overflow-hidden transition-opacity hover:opacity-50 pointer-events-none"
      >
        <div className="relative w-full h-full">
          <div
            className="absolute z-10 h-1/2 w-3/4 rounded-lg"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "0 0 0 2000px rgba(0, 0, 0, 0.314)",
            }}
          />
          <div
            className="absolute z-20 h-px bg-red-500 w-3/4 rounded-lg"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "0 0 8px 0px rgba(255, 0, 0, 1)",
            }}
          />
          <video
            style={{
              transform: isEnvironment ? "" : "scaleX(-1)",
            }}
            className="w-full h-full rounded-lg"
            ref={videoRef}
          />
        </div>
      </div>
    )
  );
};

const flashAudio = new Audio("/medias/flashsound.mp3");
const playFlashSound = () => {
  flashAudio.play();
};
