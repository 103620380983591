import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ChartPieIcon, CheckIcon, XIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { ProductGraphModalAtom } from "./graph-modal";
import { InputCounter } from "@atoms/input/input-counter";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { ReassortProductLine } from "@features/supplier/types";
import { CommonPagination } from "@features/types";
import { Title } from "@atoms/text";
import { getReassortTabColumns } from "./reassort-tabs";
import Select from "@atoms/input/input-select";
import { Input } from "@atoms/input/input-text";
import toast from "react-hot-toast";

const ReassortPage = () => {
  const { current } = useShopLocations();
  const [tabIndex, setTabIndex] = useState<number | string>(
    current && current.items ? current!.items[0].codeTypeProd : ""
  );
  const [currentModeGest, setCurrentModeGest] = useState<string>(
    current && current.items ? current!.items[0].modeGest : "P"
  );
  const { loading, getReassortLines, updateReassortLine } = useSuppliers();
  const [reassortLines, setReassortLines] = useState<{
    items: ReassortProductLine[];
    pagination: CommonPagination;
  }>({
    items: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
  });
  const setProductGraphModal = useSetRecoilState(ProductGraphModalAtom);

  const handleLineChange = (rowId: string, newValue: string | undefined) => {
    setReassortLines((prevReassortLines) => {
      const index = prevReassortLines.items.findIndex((l) => l.id === rowId);
      console.log(index);
      if (index !== -1) {
        const updatedItem = {
          ...prevReassortLines.items[index],
          decis: newValue,
        };
        const updatedItems = [
          ...prevReassortLines.items.slice(0, index),
          updatedItem,
          ...prevReassortLines.items.slice(index + 1),
        ];
        return {
          ...prevReassortLines,
          items: updatedItems,
        };
      }
      return prevReassortLines;
    });
  };

  const commonCols = [
    {
      title: "Quant.",
      id: "qte",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div style={{ maxWidth: 128, minWidth: 96 }}>
          <InputCounter
            size={"sm"}
            deleteIconOnZero
            value={1}
            onChange={(value) => {}}
          />
        </div>
      ),
    },
    {
      title: "",
      id: "product_infos_btn",
      orderable: true,
      sticky: true,
      render: (p) => (
        <div className="w-full flex gap-2 justify-center">
          <Button
            className="shrink-0"
            size={"sm"}
            onClick={(e) => setProductGraphModal({ open: true, product: p })}
            theme="secondary"
            data-tooltip="Voir infos ventes"
            data-tooltip-id="my-tooltip-inline"
            data-click="Disponible prochainement !"
            icon={({ className }) => <ChartPieIcon className={className} />}
          />
        </div>
      ),
    },
    {
      title: "Choix",
      id: "choix_decis",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div className="flex flex-col gap-1">
          <Select
            size="sm"
            value={p.decis || "-"} // Liaison de la valeur avec l'état actuel
            onChange={(e) => handleLineChange(p.id, e.target.value)}
          >
            <option value="-">-</option>
            <option value="ATT">Attendre</option>
            <option value="CDE">Commander</option>
            <option value="ABD">Abandonner</option>
          </Select>
          {p.decis && p.decis !== "-" && (
            <div className="flex gap-1">
              <Input size="sm" placeholder="ex: panier..."></Input>
              <Button
                theme="valid"
                size="sm"
                data-tooltip="Valider choix"
                onClick={async () => {
                  const res = await updateReassortLine(p.id, p.decis);
                  console.log(res);
                  if (res) {
                    toast.success("Ligne mise à jour avec succès");
                    const updatedLines = await getReassortLines(
                      currentModeGest,
                      tabIndex.toString(),
                      {
                        pageNumber: 1,
                        pageSize: 10,
                        orderBy: "ean13", // À modifier
                        orderDir: "DESC",
                      }
                    );
                    console.log(updatedLines);
                    if (updatedLines) setReassortLines(updatedLines);
                  } else {
                    toast.error("Erreur lors de la mise à jour de la ligne");
                  }
                }}
                icon={({ className }) => <CheckIcon className={className} />}
              />
              <Button
                theme="danger"
                size="sm"
                data-tooltip="Annuler choix"
                onClick={() => handleLineChange(p.id, undefined)}
                icon={({ className }) => <XIcon className={className} />}
              />
            </div>
          )}
        </div>
      ),
    },
  ] as Column<any>[];

  return (
    <Page loading={loading}>
      <Title>Réassort</Title>
      <div className="flex flex-col grow items-center gap-6">
        <Tabs
          tabs={
            current?.items
              ? current?.items?.map((el) => {
                  return { value: el.codeTypeProd, label: el.libTypeProd };
                })
              : []
          }
          value={tabIndex}
          onChange={async (v) => {
            if (current && current.items) {
              setCurrentModeGest(
                current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                  "P"
              );
            }
            setTabIndex(v);
          }}
        />{" "}
        <div className="w-full">
          <Table
            className="w-full overflow-x-scroll"
            codePres={`reas${currentModeGest}`}
            key={tabIndex}
            data={reassortLines.items}
            total={80}
            showPagination={"full"}
            onRequestData={async ({ page, perPage, order, orderBy }) => {
              const result = await getReassortLines(
                currentModeGest,
                tabIndex.toString(),
                {
                  pageNumber: page,
                  pageSize: perPage,
                  orderBy: orderBy === 0 ? "ean13" : "ean13", // À modifier
                  orderDir: order,
                }
              );
              if (result) setReassortLines(result);
            }}
            columns={[...getReassortTabColumns(currentModeGest), ...commonCols]}
          />
        </div>
      </div>
    </Page>
  );
};

export default ReassortPage;
