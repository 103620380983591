import { SublistModal } from "@molecules/sublist-modal";
import { atom } from "recoil";

export const EcoPartModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "EcoPartModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const EcoPartModal = () => {
  return (
    <SublistModal
      atom={EcoPartModalAtom}
      schema={[
        { key: "codeTaxeEco", label: "Code", type: "text" },
        { key: "libTaxeEco", label: "Libellé", type: "text" },
        {
          key: "mtTaxeHt",
          label: "Montant",
          type: "formatted",
          format: "price",
        },
      ]}
    />
  );
};
