import { useSetRecoilState } from "recoil";
import { useBasketsActions } from "./products/state/use-baskets-actions";
import { AccountModalAtom } from "@views/client/account/modal";
import { ROUTES } from "./routes";
import { useNavigate } from "react-router-dom";

type ActionType = {
  name: string;
  keywords: string;
  action: () => void;
};

export const useActions = (): ActionType[] => {
  const navigate = useNavigate();
  const basketActions = useBasketsActions();
  const setAccountModal = useSetRecoilState(AccountModalAtom);

  return [
    {
      name: "Nouveau panier",
      keywords: "panier produits créer",
      action: () => {
        basketActions.createBasket([]);
      },
    },
    {
      name: "Paramètres - Raccourcis clavier",
      keywords: "paramètres raccourcis clavier shortcuts keyboard",
      action: () => {
        navigate(ROUTES.SettingsShortcuts);
      },
    },
    {
      name: "Paramètres - Raccourcis clavier",
      keywords: "paramètres raccourcis clavier shortcuts keyboard",
      action: () => {
        navigate(ROUTES.SettingsShortcuts);
      },
    },
    {
      name: "Theme",
      keywords: "theme themes Thèmes Thèmes",
      action: () => {
        navigate(ROUTES.ThemePage);
      },
    },
    {
      name: "Paramètres - Mon compte",
      keywords: "paramètres compte mot de passe password site email",
      action: () => {
        setAccountModal(true);
      },
    },
    {
      name: "Paramètres - Gestion des utilisateurs",
      keywords: "paramètres gestion utilisateurs users",
      action: () => {
        navigate(ROUTES.Unavailable);
      },
    },
    ...[
      "Canal Vente",
      "Type de produit",
      "Raison Blocage",
      "Collection",
      "Département",
      "Editeur",
      "Fabricant",
      "Disponibilité",
      "Diffuseur",
      "Conditions d'achat",
      "Fournisseur",
      "Coefficient",
      "Promotion",
      "Rayon",
      "Pass Culture",
      "Genre",
      "Rayon implicite",
      "Support",
      "Code Prix",
      "Coefficient implicite",
      "Groupe remise",
      "Type client",
      "Type remise",
      "Tarif de vente",
      "Marché",
      "Frais de port",
      "Mode de règlement",
      "Échéance de règlement",
      "Catégorie client",
      "Consommateur",
      "Mise en compte",
      "Facturation",
      "Livraison",
      "Avoirs",
      "Règlements",
      "Chorus - factures à envoyer",
      "Chorus - factures transmises",
      "Chorus - factures purgées",
      "Taxe",
      "TVA",
      "Devise",
      "Type d'achat",
      "Motif régularisation stock",
      "Axe d'analyse",
      "Panière/case",
      "Mode d'expédition",
      "Disponibilité commerciale",
      "Notation EDI",
      "Barême de livraison",
      "Log des actions",
      "Historique des actions",
      "Format étiquette",
      "Groupe étiquette",
      "Imprimante étiquette",
      "Modèle étiquette",
      "Etiqueteuse",
      "Planche d'étiquettes",
      "Poste utilisateur",
      "Suivi des demandes transferts émises",
      "Suivi des demandes transferts reçues",
      "Historique des demandes transferts émises",
      "Historique des demandes transferts reçues",
      "Réponses aux demandes transferts",
      "Suivi des bordereaux transferts émis",
      "Suivi des bordereaux transferts reçus",
      "Expédition des transferts",
      "Historique expédition des tranferts",
      "Réception des transferts",
      "Historique réception des tranferts",
      "Historique des bordeaux transferts émis",
      "Historique des bordeaux transferts reçus",
      "Régularisation de stock",
      "Suivi des régularisation de stock",
      "Historique des mouvements",
      "Actualisation des tarifs",
      "Session d'inventaire",
      "Domaine d'inventaire",
      "Tableau des comptages",
      "Comptage manuel",
      "Lieu fonction EDI",
      "Groupe EDI Implicite",
      "Groupe EDI",
      "Poste EDI",
      "Conditionnement EDI",
      "Format EDI",
      "Mode d'expédition EDI",
      "Mode transmission EDI",
      "Serveurs EDI",
      "Serveurs EDI actif",
      "Type de commande EDI",
      "Planification EDI",
      "Suivi des échecs envoi EDI",
      "Suivi des commandes à traiter EDI",
      "Réassort",
      "Réassort sur stock min max",
      "Contrôle Réassort",
      "Préparation commande fournisseur",
      "Envoi commande fournisseur",
      "Suivi commandes fournisseurs",
      "Reliquats clients",
      "Reliquats fournisseurs",
      "Historique commandes fournisseurs",
      "Bons Livraisons Fournisseurs",
      "Commandes sous-traitées",
      "Réceptions fournisseurs",
      "Suivi des réceptions",
      "Droits de retour",
      "Préparation retour fournisseur",
      "Envoi retour fournisseur",
      "Suivi retours fournisseurs",
    ].map((el) => ({
      name: el,
      keywords: el + " parametres",
      action: () => {
        navigate(ROUTES.Unavailable);
      },
    })),
  ];
};
