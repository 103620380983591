import { fetchServer } from "@features/utils/fetch-server";
import { ShopLocation } from "../types";

export class ShopLocationClient {
  static getLocations = async (userId: string) => {
    const response = await fetchServer(`/user/habilitation/${userId}`);
    const data = await response.json();

    //Temp fix for test version
    // const finalRes = data.map((shop: ShopLocation) => {
    //   return {
    //     ...shop,
    //     items: shop.items?.filter(
    //       (el: ShopProductGestionType) =>
    //         el.modeGest !== "D" && el.modeGest !== "N"
    //     ),
    //   };
    // });
    return data as ShopLocation[];
  };

  static putConnection = async (userId: string, codeLieu: string) => {
    await fetchServer("/user/connection", {
      method: "PUT",
      body: JSON.stringify({
        id: userId,
        codeLieu: codeLieu,
      }),
    });
  };
}
