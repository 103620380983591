import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";
import { TableInfos } from "./create-columns";
import { Frame } from "@atoms/layout/frame";
import { BaseSmall } from "@atoms/text";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useEffect, useState } from "react";
import { Button } from "@atoms/button/button";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { deepEqual } from "@features/utils/divers";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import toast from "react-hot-toast";
import { useAuth } from "@features/general/auth/state/use-auth";

export const TableConfigModalAtom = atom<{
  open: boolean;
  tableInfos?: TableInfos;
  commited: boolean;
  codePres: string;
}>({
  key: "TableConfigModalAtom",
  default: { open: false, commited: false, codePres: "" },
});

export const TableConfigModal = () => {
  const [tableConfigtModalAtom, setTableConfigModalAtom] =
    useRecoilState(TableConfigModalAtom);

  const [tempConfig, setTempConfig] = useState<TableInfos | undefined>();
  const [changed, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  const handleClose = (comited: boolean) => {
    setTableConfigModalAtom({
      ...tableConfigtModalAtom,
      open: false,
      commited: comited,
    });
  };

  useEffect(() => {
    setTempConfig(tableConfigtModalAtom.tableInfos);
  }, [tableConfigtModalAtom.tableInfos]);

  useEffect(() => {
    setChanged(!deepEqual(tableConfigtModalAtom.tableInfos, tempConfig));
  }, [tempConfig, tableConfigtModalAtom.tableInfos]);

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={tableConfigtModalAtom.open}
      onClose={() => handleClose(false)}
    >
      <ModalContent title={"Configuration"}>
        {tableConfigtModalAtom.tableInfos && tempConfig && (
          <Frame className="grow flex flex-col gap-2 ">
            <div className="grow grid grid-cols-2 gap-2">
              {tempConfig.tableColumns.map((c, index) => (
                <div className="col-span-1 flex gap-2 text-center">
                  <Checkbox
                    value={c.visible}
                    onChange={(newValue) => {
                      let tempColumnList = [...tempConfig.tableColumns];
                      let tempObject = {
                        ...tempColumnList[index],
                        visible: newValue,
                      };
                      tempColumnList[index] = tempObject;
                      setTempConfig({
                        ...tempConfig,
                        tableColumns: tempColumnList,
                      });
                    }}
                  />
                  <BaseSmall>{c.libCol}</BaseSmall>
                </div>
              ))}
            </div>
          </Frame>
        )}
        <div className="w-full flex flex-row gap-2 justify-end mt-2">
          <Button
            disabled={!changed || loading}
            theme="danger"
            data-tooltip="Annuler modifications"
            icon={({ className }) => <XIcon className={className} />}
            onClick={() => setTempConfig(tableConfigtModalAtom.tableInfos)}
          >
            Annuler modifications
          </Button>
          <Button
            disabled={!changed || loading}
            loading={loading}
            theme="valid"
            data-tooltip="Valider modifications"
            icon={({ className }) => <CheckIcon className={className} />}
            onClick={async () => {
              setLoading(true);
              if (tempConfig)
                try {
                  await CommonApiClient.updateTablePres(
                    user!.id,
                    tableConfigtModalAtom.codePres,
                    tempConfig
                  );
                  setLoading(false);
                  toast.success("Présentation du tableau mise à jour.");
                  handleClose(true);
                } catch {
                  toast.error(
                    "Problème rencontré lors de la mise à jour de la présentation du tableau."
                  );
                  setLoading(false);
                }
            }}
          >
            Valider modifications
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
