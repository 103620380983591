import { SublistModal } from "@molecules/sublist-modal";
import { atom } from "recoil";

export const ProdEanModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "ProdEanModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const ProdEanModal = () => {
  return (
    <SublistModal
      atom={ProdEanModalAtom}
      schema={[
        { key: "ean13", label: "EAN13", type: "text" },
        {
          key: "refVente",
          label: "Réference vente",
          type: "text",
        },
        { key: "actif", label: "Actif", type: "boolean" },
        {
          key: "origCreat",
          label: "Creation",
          type: "text",
        },
      ]}
    />
  );
};
