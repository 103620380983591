import { Button } from "@atoms/button/button";
import { BaseSmall, InfoSmall } from "@atoms/text";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { useBaskets } from "@features/products/state/use-baskets";
import { ProductTypeShort, ProductsBasketType } from "@features/products/types";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/format/dates";
import { ellipsis } from "@features/utils/format/strings";
import { useDomElement } from "@features/utils/hooks/use-dom-element";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { HeaderSeparator, HeaderWidgetsDomId } from "@views/_layout/header";
import _ from "lodash";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";

export const ProductsBasketsWidget = () => {
  const location = useLocation();
  //const isOpen = location.pathname.indexOf(ROUTES.Product) === 0;
  //Temporaire : on voit les paniers sur client aussi --> utile lors de l'ajout d'article

  const isOpen = location.pathname !== ROUTES.Home;
  const el = useDomElement(HeaderWidgetsDomId);

  return <>{isOpen && el && createPortal(<BasketIconList />, el)}</>;
};

export const BasketIconList = () => {
  const {
    baskets: productsBaskets,
    current: currentBasket,
    setCurrent,
    getProductsCount,
    updateBasket,
  } = useBaskets();
  const navigate = useNavigate();
  const location = useLocation();

  const baskets = _.sortBy(productsBaskets, (a) =>
    a.products.length === 0 ? -Date.now() : -a.created_at
  );

  const handleDragOver = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (
    e: React.DragEvent<HTMLButtonElement>,
    basketID: string
  ) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("application/reactflow");
    const product: ProductTypeShort = JSON.parse(data);
    if (!product.referGestion)
      await ProductsApiClient.referenceProduct(product.id);
    updateBasket(basketID, {
      add: [{ product, count: 1 }],
    });
  };

  return (
    <>
      <div
        className="inline h-9 overflow-auto sm:overflow-visible"
        style={{ maxWidth: "calc(100vw - 15rem)" }}
      >
        <div className="flex flex-row space-x-2">
          {baskets
            .filter((b) => !b.temporary)
            .map((basket) => (
              <Button
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, basket.id)}
                key={basket.id}
                data-position="bottom"
                data-tooltip={
                  formatTime(basket.created_at) +
                  (basket.customer?.numCli
                    ? " Client: " +
                      `${basket.customer.nomCli} ${basket.customer.prnomCli} - `
                    : "") +
                  (currentBasket === basket.id ? " (panier courant)" : "") +
                  ", " +
                  (basket.products
                    .map((e) => ellipsis(e.product.designation, 25))
                    .join(", ") || "vide.")
                }
                theme={currentBasket === basket.id ? "danger" : "primary"}
                className={
                  "relative overflow-visible " +
                  (currentBasket === basket.id ? "" : "opacity-50 ")
                }
                onClick={() => {
                  setCurrent(basket.id);
                  if (
                    currentBasket === basket.id ||
                    location.pathname.startsWith(
                      ROUTES.Basket.replace(":id", "")
                    )
                  ) {
                    if (
                      basket.products.length === 0 &&
                      currentBasket !== basket.id
                    ) {
                      navigate(ROUTES.Products);
                    } else {
                      navigate(
                        ROUTES.Basket.replace(/:id/, basket.id.toString())
                      );
                    }
                  }
                }}
              >
                <ShoppingCartIcon className="h-5 w-5 -mx-2" />
                <div
                  className={
                    "absolute text-white flex items-center justify-center h-5 w-6 border-2 border-white border-solid -top-0.5 -right-0.5 sm:-top-1 sm:-right-2 rounded-full " +
                    (currentBasket === basket.id ? "bg-red-500" : "bg-blue-600")
                  }
                >
                  <InfoSmall noColor>{getProductsCount(basket.id)}</InfoSmall>
                </div>
              </Button>
            ))}
        </div>
      </div>
      <div className="relative -top-0.5">
        <HeaderSeparator />
      </div>
    </>
  );
};

export const BasketIcon = (props: {
  className: string;
  basket: ProductsBasketType;
  nbArticle: number;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={props.onClick}
      className="grow flex flex-row hover:cursor-pointer m-1 "
    >
      <ShoppingCartIcon className={props.className} />
      <div className="h-full flex flex-col justify-start">
        <BaseSmall>{props.nbArticle}</BaseSmall>
      </div>
    </div>
  );
};
