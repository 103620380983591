import { Modal, ModalContent } from "@atoms/modal/modal";
import { dataLibrairie } from "@views/client/dashboard/fakeData";
import { atom, useRecoilState } from "recoil";
import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button } from "@atoms/button/button";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import Select from "@atoms/input/input-select";
import { Frame } from "@atoms/layout/frame";
import { BaseBold } from "@atoms/text";
import { ReassortProductLine } from "@features/supplier/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

type ProductGraphModalType = {
  open: boolean;
  product?: ReassortProductLine;
};

export const chartData = {
  labels: dataLibrairie.map((el) => el.mois),
  datasets: [
    {
      type: "line" as const,
      label: "Ventes",
      borderColor: "#06b6d4",
      borderWidth: 3,
      fill: false,
      data: dataLibrairie.map((el) => el.revenus),
    },
  ],
};

export const ProductGraphModalAtom = atom<ProductGraphModalType>({
  key: "ProductGraphModalAtom",
  default: { open: false },
});

export const ProductGraphModal = () => {
  const [productGraphModal, setProductGraphModal] = useRecoilState(
    ProductGraphModalAtom
  );

  const handleClose = () => {
    setProductGraphModal({ open: false, product: undefined });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={productGraphModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Information sur le produit: NOM DU PRODUIT"}>
        <div className="flex flex-col gap-1 md:gap-2">
          {productGraphModal.product && (
            <Frame className="w-full flex flex-row justify-between items-center">
              <Chart
                className="maw-h-40 max-w-sm"
                type="bar"
                data={chartData}
              />
              {/* <StockTab product={productGraphModal.product as ProductType} /> */}
            </Frame>
          )}
          <div className="w-full flex gap-2 justify-center">
            <div className="flex flex-col justify-center">
              <BaseBold>Choix: </BaseBold>
            </div>
            <Select>
              <option>Attendre</option>
              <option>Commander</option>
              <option>Abandonner</option>
            </Select>
            <Button
              theme="valid"
              data-tooltip="Valider choix"
              onClick={() => handleClose()}
              icon={({ className }) => <CheckIcon className={className} />}
            >
              Valider
            </Button>
            <Button
              theme="danger"
              data-tooltip="Annuler choix"
              onClick={() => handleClose()}
              icon={({ className }) => <XIcon className={className} />}
            >
              Annuler
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
