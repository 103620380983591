import { atom } from "recoil";
import { ReceptionLine, ReceptionUnit } from "../types";
import { CommonPagination } from "@features/types";
import { persistAtom } from "@features/utils/hooks/use-local-storage";

export const AllOngoingReceptions = atom<{
  pagination: CommonPagination;
  items: ReceptionUnit[];
}>({
  key: "AllOngoingReceptions",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as ReceptionUnit[],
  },
});

export const ReceiptLines = atom<{
  pagination: CommonPagination;
  items: ReceptionLine[];
}>({
  key: "ReceiptLines",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as ReceptionLine[],
  },
});

//Valeures de test temporaires
export const SelectedReception = persistAtom<ReceptionUnit>("user", {
  key: "SelectedReception",
  default: {} as ReceptionUnit,
});

export const SelectedItemReception = atom<ReceptionLine | null>({
  key: "SelectedItemReception",
  default: null,
});

export const PreviousScannedProduct = atom<ReceptionLine | null>({
  key: "PreviousScannedProductReception",
  default: null,
});

export const LockItemReception = atom<boolean>({
  key: "LockItemReception",
  default: false,
});

export const TabKey = atom<{ count: number; refresh: boolean }>({
  key: "TabKeyReception",
  default: { count: 0, refresh: true },
});
