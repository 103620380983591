import { useRecoilState } from "recoil";
import { ForgotPwdClient } from "../api-client/api-client";
import { LoginRefer, VerifCode } from "./store";

export const useForgot = () => {
  const [verifCode, setVerifCode] = useRecoilState(VerifCode);
  const [loginRefer, setLoginRefer] = useRecoilState(LoginRefer);

  const getCode = async (userId: string) => {
    try {
      await ForgotPwdClient.getCode(userId);
    } catch (err) {
      console.log(err);
    }
  };

  const checkCode = async (code: string) => {
    try {
      const valid = await ForgotPwdClient.checkCode(loginRefer, code);
      if (valid) setVerifCode(code);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const setNewPwd = async (pwd: string) => {
    const result = await ForgotPwdClient.setNewPwd(loginRefer, verifCode, pwd);
    return result;
  };

  const changeLoginRefer = (refer: string) => {
    setLoginRefer(refer);
  };

  return {
    getCode,
    checkCode,
    setNewPwd,
    changeLoginRefer,
  };
};
