import React, { useEffect, useState } from "react";
import { Base } from "@atoms/text";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

interface AccordionProps {
  panels: {
    node: React.ReactNode;
    label: JSX.Element | string;
    value: string | number;
    invisible?: boolean;
    suffix?: JSX.Element | string;
  }[];
  value?: { [key: string]: boolean };
  onChange?: (value: { [key: string]: boolean }) => void;
  parentClassName?: string;
}

const panelClassName =
  "text-sm cursor-pointer h-12 px-4 flex items-center border border-slate-200 dark:border-slate-600 border-solid bg-slate-100 dark:bg-slate-800 hover:text-blue-600 transition-colors";
const activePanelClassName =
  " text-blue-500 border-b-2 border-b-blue-500 dark:border-b-blue-500 ";
const inactivePanelClassName = " text-zinc-500";

export default function Accordion(props: AccordionProps) {
  const [activePanels, setActivePanels] = useState<{ [key: string]: boolean }>(
    props.value || {}
  );

  const togglePanel = (value: string | number) => {
    const newState = { ...activePanels, [value]: !activePanels[value] };
    setActivePanels(newState);
    if (props.onChange) {
      props.onChange(newState);
    }
  };

  useEffect(() => {
    setActivePanels(props.value || {});
  }, [props.value]);

  return (
    <>
      <div
        className={`overflow-auto w-100 transition-all select-none ${props.parentClassName}`}
      >
        {props.panels.map((panel, i) => {
          const cl =
            panelClassName +
            (activePanels[panel.value]
              ? activePanelClassName
              : inactivePanelClassName);
          return (
            <div
              key={panel.value}
              className={
                panel.invisible
                  ? "hidden"
                  : "transition-all " +
                    (activePanels[panel.value]
                      ? i > 0
                        ? "my-1"
                        : "mb-1"
                      : i > 0
                      ? "-mt-px"
                      : "")
              }
            >
              <Base
                noColor
                className={cl + " flex flex-row items-center"}
                onClick={() => togglePanel(panel.value)}
              >
                {activePanels[panel.value] ? (
                  <ChevronUpIcon className="h-4 w-4 mr-2 -ml-1" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 mr-2 -ml-1" />
                )}
                <div className="grow">{panel.label}</div>

                {panel.suffix}
              </Base>
              {activePanels[panel.value] && (
                <div className="border border-slate-200 dark:border-slate-600 border-solid border-t-none dark:border-t-none bg-slate-100 dark:bg-slate-800 p-2">
                  {panel.node}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
