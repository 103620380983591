import { SublistModal } from "@molecules/sublist-modal";
import { atom } from "recoil";

export const PricesModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "PricesModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const PricesModal = () => {
  return (
    <SublistModal
      atom={PricesModalAtom}
      schema={[
        { key: "codeTarif", label: "Code Tarif", type: "text" },
        { key: "eaN13", label: "EAN13", type: "text" },
        { key: "dateAppli", label: "Date application", type: "date" }, // ceci est une chaîne, mais représente une date ISO; pour une utilisation plus stricte, vous pourriez envisager d'utiliser une bibliothèque de gestion de date ou le type Date.
        { key: "dateFinValid", label: "Date fin validité", type: "date" }, // même situation que 'dateAppli' */
        { key: "qteMini", label: "Qte min.", type: "number" },
        {
          key: "prixVente",
          label: "Prix vente",
          type: "formatted",
          format: "price",
        },
        {
          key: "prixVenteHT",
          label: "Prix vente HT",
          type: "formatted",
          format: "price",
        },
        {
          key: "prixVenteTTC",
          label: "Prix vente TTC",
          type: "formatted",
          format: "price",
        },
        /* {
          key: "coeffPR",
          label: "Coefficient PR",
          type: "formatted",
          format: "percentage",
        }, */
        { key: "indicHT", label: "HT", type: "boolean" },
        { key: "indicPrixNet", label: "Prix Net", type: "boolean" },
        { key: "idBD", label: "ID BD", type: "text" },
        /* { key: "dateCreat", label: "Créé", type: "date" }, // même situation que 'dateAppli'
        { key: "dateMAJ", label: "Mis à jour", type: "date" }, // même situation que 'dateAppli' */
        /* { key: "utilCreat", label: "Créateur", type: "text" },
        { key: "utilMAJ", label: "Éditeur", type: "text" }, */
      ]}
    />
  );
};
