import { createPortal } from "react-dom";
import { ScanWidgetDomId } from "./_layout/scan";
import { BarcodeScanner } from "@atoms/input-scan/scanner";
import { useScan } from "@atoms/input-scan/use-scan";

export const ScanWidget = () => {
  const { scanOn } = useScan();

  const el = document.getElementById(ScanWidgetDomId);
  return <>{el && scanOn && createPortal(<BarcodeScanner />, el)}</>;
};
