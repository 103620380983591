import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/decoration-label";
import { Input } from "@atoms/input/input-text";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { InfoSmall } from "@atoms/text";
import { useAccount } from "@features/general/account/state/use-account";
import { PasswordRules } from "@features/settings/shortcuts/password-rules";
import { validatePassword } from "@features/utils/format/strings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";

export const AccountPasswordModalAtom = atom({
  key: "AccountPasswordModalAtom",
  default: { open: false, forced: false, v: "" },
});

export const AccountPasswordModal = () => {
  const { t } = useTranslation();
  const [accountPasswordModal, setAccountPasswordModal] = useRecoilState(
    AccountPasswordModalAtom
  );
  const { changePassword } = useAccount();

  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassowrd] = useState("");
  const [newPassword, setNewPassowrd] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const handleClose = () => {
    setCurrentPassowrd("");
    setNewPassowrd("");
    setConfirmPassowrd("");
    setAccountPasswordModal({ open: false, forced: false, v: "" });
  };

  return (
    <>
      <Modal
        open={accountPasswordModal.open}
        onClose={() => handleClose()}
        className="sm:max-w-[550px]"
      >
        <ModalContent title={t("account.password.title")}>
          {!accountPasswordModal.forced && (
            <>
              <InputLabel
                label={t("account.password.currentpassword")}
                className="mt-4"
                input={
                  <Input
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassowrd(e.target.value);
                    }}
                    type="password"
                  />
                }
              />{" "}
            </>
          )}
          {accountPasswordModal.forced && (
            <Frame className="p-0 border-2 border-yellow-500">
              <InfoSmall noColor={true} className="text-orange-400">
                Votre mot de passe actuel ne respecte pas nos normes de
                sécurité. Pour la sécurité de votre compte, veuillez le mettre à
                jour.
              </InfoSmall>
            </Frame>
          )}

          <InputLabel
            label={t("account.password.newpassword")}
            className="mt-4"
            input={
              <Input
                value={newPassword}
                onChange={(e) => {
                  setNewPassowrd(e.target.value);
                  setValidPassword(validatePassword(e.target.value));
                }}
                type="password"
              />
            }
          />

          <InputLabel
            label={t("account.password.confirmpassword")}
            className="mt-4"
            input={
              <Input
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassowrd(e.target.value);
                }}
                type="password"
              />
            }
          />
          <div className="flex w-full justify-between my-4">
            <Button
              className="block mt-4"
              theme="primary"
              onClick={async () => {
                //setAccountPasswordModal(false);
                //logout();
                setLoading(true);
                if (!accountPasswordModal.forced) {
                  if (await changePassword(currentPassword, newPassword))
                    handleClose();
                } else {
                  if (
                    await changePassword(accountPasswordModal.v, newPassword)
                  ) {
                    handleClose();
                  }
                }
                setLoading(false);
              }}
              disabled={
                (!accountPasswordModal.forced && currentPassword === "") ||
                newPassword === "" ||
                !validPassword ||
                newPassword !== confirmPassword
              }
              loading={loading}
            >
              {t("account.password.validate")}
            </Button>
            {accountPasswordModal.forced && (
              <Button
                className="block mt-4"
                theme="warning-outlined"
                onClick={async () => handleClose()}
              >
                Effectuer plus tard
              </Button>
            )}
          </div>
          <Frame className="mt-2">
            <div>
              <InfoSmall>Règles pour le mot de passe : </InfoSmall>
              <ul className="list-disc">
                <div className="w-full ml-6 ">
                  {PasswordRules.gPassword.rules.map((rule, index) => (
                    <li key={index}>
                      <InfoSmall>{rule}</InfoSmall>
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          </Frame>
        </ModalContent>
      </Modal>
    </>
  );
};
