import { Book, Paper, ProductType } from "@features/products/types";
import { BookForm } from "./book";
import { PaperForm } from "./paper";
import { SearchFormFieldType } from "@molecules/form/types";

export type Fiche = {
  general: SearchFormFieldType[];
  identification: SearchFormFieldType[];
  classification: SearchFormFieldType[];
  venteGeneral: SearchFormFieldType[];
  venteCanal: SearchFormFieldType[];
  approvisionnement: SearchFormFieldType[];
  stock: SearchFormFieldType[];
};

export const formDisplayer = (
  product: ProductType | Paper | Book | null,
  productType: string
) => {
  switch (productType) {
    case "D":
      return BookForm(product as Book);
    case "L":
      return BookForm(product as Book);
    case "N":
      return BookForm(product as Book);
    case "S":
      return BookForm(product as Book);
    case "P":
      return PaperForm(product as Paper);
    default:
      return BookForm(product as Book);
  }
};

const classificationKeys = [
  "indicScol",
  "passCulture",
  "theme",
  "libFamil",
  "libSousFamil",
  "libSegment",
  "pays",
];
export const isPanelEmpty = (product: ProductType) => {
  let obj: { [key: string]: any } = {};
  classificationKeys.map(
    (key) => (obj[key] = product[key as keyof ProductType])
  );

  const allNull = Object.values(obj).every((value) => value === null);
  const allUndef = Object.values(obj).every((value) => value === undefined);
  const allShort = Object.values(obj).every((value) => value === "");

  return allNull || allUndef || allShort;
};
