// https://chuckconway.com/changing-a-react-input-value-from-vanilla-javascript/
export function setNativeValue(element: any, value: string) {
  if (element) {
    const lastValue = element.value;
    element.value = value;
    const event: any = new Event("input", {
      target: element,
      bubbles: true,
    } as any);
    // React 15
    event.simulated = true;
    // React 16
    const tracker = element._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
  }
}
