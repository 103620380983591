import { CheckIcon } from "@heroicons/react/outline";
import { BaseSmall } from "../text";

export const Checkbox = (props: {
  label?: string;
  onChange?: (status: boolean, e: React.MouseEvent<HTMLInputElement>) => void;
  value?: boolean;
  className?: string;
  disabled?: boolean;
}) => {
  const renderSwitch = () => {
    const className = props.className || "";

    return (
      <div
        className={
          " shrink-0 flex justify-center items-center w-5 h-5 border-2 text-white " +
          (props.value
            ? "border-blue-500 bg-blue-500 hover:border-blue-600 hover:bg-blue-600 dark:border-sky-900 dark:bg-sky-900 dark:hover:border-sky-700 dark:hover:bg-sky-700"
            : "border-slate-300 " +
              (props.disabled ? "" : "hover:border-blue-300")) +
          " " +
          (props.disabled ? "opacity-50" : "cursor-pointer") +
          " " +
          (className || "")
        }
        onClick={(e) =>
          !props.label &&
          !props.disabled &&
          props.onChange &&
          props.onChange(!props.value, e as React.MouseEvent<HTMLInputElement>)
        }
      >
        {props.value && <CheckIcon className="m-icon-small" />}
      </div>
    );
  };

  if (props.label) {
    return (
      <div
        className={
          "w-full overflow-hidden flex flex-row items-center space-x-2"
        }
        onClick={(e) => {
          if (!props.disabled) {
            props.onChange &&
              props.onChange(
                !props.value,
                e as React.MouseEvent<HTMLInputElement>
              );
          }
        }}
      >
        {renderSwitch()}
        <div className="grow overflow-hidden text-ellipsis whitespace-nowrap">
          <BaseSmall
            data-tooltip={props.label}
            className={
              "overflow-hidden text-ellipsis whitespace-nowrap " +
              (props.disabled ? "opacity-50" : "cursor-pointer")
            }
          >
            {props.label}
          </BaseSmall>
        </div>
      </div>
    );
  } else {
    return renderSwitch();
  }
};
