import { atom } from "recoil";

//Email de l'utilisateur
export const LoginRefer = atom<string>({
  key: "loginRefer",
  default: "",
});

//Code que l'utilisateur va recevoir par mail/SMS
export const VerifCode = atom({
  key: "verifCode",
  default: "",
});

//Booléen qui change si le code est validé après vérification par le serveur
export const ValidCode = atom({
  key: "validCode",
  default: false,
});
