import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { Table } from "@molecules/table";
import { atom, useRecoilState } from "recoil";

export const ListSupplierProductsModalAtom = atom({
  key: "ListSupplierProductsModal",
  default: { open: false, products: [] as any[] },
});

export const SupplierProductsModal = () => {
  const [listSupplierProductsModal, setListSupplierProductsModal] =
    useRecoilState(ListSupplierProductsModalAtom);
  const { current: currentLocation } = useShopLocations();

  return (
    <Modal
      className="w-full sm:!w-[90%] !max-w-full"
      open={listSupplierProductsModal.open}
      onClose={() =>
        setListSupplierProductsModal({ open: false, products: [] })
      }
    >
      <ModalContent title="Rapatriement">
        <Table
          data={listSupplierProductsModal.products}
          columns={[
            {
              title: "Articles",
              render: (p) => (
                <div
                  className="flex flex-row w-full items-center space-x-4"
                  onClick={() => {}}
                >
                  <div className="flex flex-col">
                    <Base
                      className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal w-full cursor-text"
                      data-html={true}
                    >
                      {p.desig}
                    </Base>
                    <Info className="cursor-text">
                      {p.refProdLivr}
                      {currentLocation?.devisSymb}
                    </Info>
                  </div>
                </div>
              ),
            },
            {
              title: "Quantité",
              render: (p) => (
                <Base className="w-full text-right">{p.qteLivr | p.qte}</Base>
              ),
            },
            {
              title: `Prix achat unit (${
                currentLocation?.devisSymb
              }) - Total: ${listSupplierProductsModal.products
                .reduce(
                  (total, item) =>
                    total +
                    (item.prixNetHtLivr | item.prixNetHt) *
                      (item.qteLivr | item.qte),
                  0
                )
                .toFixed(currentLocation?.devisNbDecim)} ${
                currentLocation?.devisSymb
              }`,
              render: (p) => (
                <Base className="w-full text-right">
                  {p.prixNetHtLivr | p.prixNetHt}
                  {currentLocation?.devisSymb}
                </Base>
              ),
            },
            {
              title: `Prix vente TTC (${
                currentLocation?.devisSymb
              }) - Total: ${listSupplierProductsModal.products
                .reduce(
                  (total, item) =>
                    total +
                    (item.prixPublicTtcLivr | item.prixBase) *
                      (item.qteLivr | item.qte),
                  0
                )
                .toFixed(currentLocation?.devisNbDecim)} ${
                currentLocation?.devisSymb
              }`,
              render: (p) => (
                <Base className="w-full text-right">
                  {p.prixPublicHtLivr | p.prixBase}
                  {currentLocation?.devisSymb}
                </Base>
              ),
            },
          ]}
        />
      </ModalContent>
    </Modal>
  );
};
