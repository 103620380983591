import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Section, Title } from "@atoms/text";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const SettingsThemePage = () => {
  const { t } = useTranslation();
  const [themeRounded, setThemeRounded] = useState(() => {
    const theme = localStorage.getItem("theme");
    return theme === "rounded";
  });

  const toggleTheme = () => {
    //const nextTheme = themeRounded ? "default" : "rounded";
    setThemeRounded(!themeRounded);
    //localStorage.setItem("theme", nextTheme);
    const link = document.getElementById(
      "tailwind-stylesheet"
    ) as HTMLLinkElement;

    if (link) {
      link.href = themeRounded
        ? "/styles/tailwind.css?t=1"
        : "/styles/tailwind-rounded.css?t=1";
      //link.href = "/styles/tailwind-rounded.css?t=1";
    }
  };

  return (
    <Page>
      <Title>{t("settings.theme.title", "Thème")}</Title>
      <br />
      <Section>{t("settings.theme.rounded", "Mode arrondi")}</Section>
      <div className="flex flex-row gap-2">
        <Button disabled={!themeRounded} onClick={() => toggleTheme()}>
          {t("Theme classique")}
        </Button>
        <Button disabled={themeRounded} onClick={() => toggleTheme()}>
          {t("Theme secondaire")}
        </Button>
      </div>
    </Page>
  );
};
