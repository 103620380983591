import { ProductToolTip } from "@features/products/types";
import { PasswordRules } from "@features/settings/shortcuts/password-rules";

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validatePassword = (password: string) => {
  const defaultRegex = new RegExp(PasswordRules.gPassword.default.ctrl);
  const secureRegex = new RegExp(PasswordRules.gPassword.secure.ctrl);

  return defaultRegex.test(password) && secureRegex.test(password);
};

export const stringToColor = (str: string, saturation = 80, lightness = 60) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export const ellipsis = (str: string, length: number) => {
  if (str) return str.length > length ? str.substring(0, length) + "..." : str;
  return "";
};

export const formatAmount = (
  number: number,
  currency: string = "€",
  nbDecim?: number
): string => {
  // Si le nombre de décimales n'est pas défini, par défaut on utilise 2
  const decimals = nbDecim !== undefined && nbDecim !== 0 ? nbDecim : 2;

  // On arrondit le nombre au nombre de décimales spécifié
  const roundedNumber = number.toFixed(decimals);

  // On vérifie si les chiffres après la virgule sont tous des zéros
  if (
    nbDecim === 0 &&
    parseFloat(roundedNumber) === Math.floor(parseFloat(roundedNumber))
  ) {
    // Si c'est le cas, on ne garde pas de décimales
    return Math.floor(number).toString() + ` ${currency}`;
  }

  // Sinon, on garde le nombre de décimales spécifié
  return roundedNumber + ` ${currency}`;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString("fr-FR");
};

export const applySearchFilter = (search: string, item: string) => {
  search = search || "";
  item = item || "";

  //item = search // monkey patch
  const normalizedSearch = search
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase(); // Normalize and convert to lowercase for case-insensitive comparison

  const normalizedItem = item
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase(); // Normalize and convert to lowercase for case-insensitive comparison

  const searchWords = normalizedSearch.split(/\s+/); // Split search string into words
  return searchWords.every((word) => normalizedItem.includes(word));
};

export const normalizeString = (str: string) => {
  return str
    .normalize("NFD") // Décompose les lettres en leurs composants diacritiques de base
    .replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques
    .toLowerCase(); // Convertit la chaîne en minuscules
};

export const buildSearchURL = (params: any): string => {
  const urlParams = new URLSearchParams(params as any).toString();
  return urlParams;
};

// export const getCommonProductTooltip = (product: any, modeGest: string) => {
//   if (product.codeEtat && product.codeEtat === "S") return "État: Supprimé";
//   return `<div style='white-space: pre-line;'>${
//     modeGest === "L"
//       ? `Auteur: ${
//           product.auteur !== "" ? product.auteur : "inconnu"
//         }&#10; Éditeur: ${product.editeur}`
//       : modeGest === "P"
//       ? `${product.idBd !== "" ? `idBD: ${product.idBd}` : product.designation}`
//       : product.designation
//   }${
//     product.codeEtat && product.codeEtat !== ""
//       ? `&#10;État: ${product.codeEtat}`
//       : ""
//   }</div>`;
// };

export const getCommonProductTooltip = (
  product: any,
  t: ProductToolTip
): string => {
  let content = "";

  // Utilisation d'un switch pour changer le contenu en fonction de modeGest
  switch (t.modeGest) {
    case "L":
      content = `Auteur: ${
        t.auteurFabricant !== "" ? t.auteurFabricant : "inconnu"
      }&#10;Éditeur: ${t.editMarque}`;
      break;
    case "P":
      content = `${t.idBD !== "" ? `idBD: ${t.idBD}` : product.designation}`;
      if (t.auteurFabricant && t.auteurFabricant !== "") {
        content += `&#10;Fabricant: ${t.auteurFabricant}`;
      }
      if (t.editMarque && t.editMarque !== "") {
        content += `&#10;Marque: ${t.editMarque}`;
      }
      break;
    default:
      content = product.designation;
      break;
  }

  // Ajout des autres attributs (excluant ceux contenant "code" et modeGest)
  if (t.id && t.id !== "") {
    content += `&#10;ID: ${t.id}`;
  }
  if (t.etat && t.etat !== "") {
    content += `&#10;État: ${t.etat}`;
  }

  return `<div style='white-space: pre-line;'>${content}</div>`;
};
