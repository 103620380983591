import { DropdownRoot } from "@atoms/dropdown";
import { ProductsBasketsWidget } from "./client/products/baskets/header-widget";
import { ScanWidget } from "./scan";
import { ScanIconTriggerWidget } from "./scan-icons";
import { CustomerModeWidget } from "./user-mode";

export const Portals = () => {
  return (
    <>
      <ProductsBasketsWidget />
      <ScanWidget />
      <ScanIconTriggerWidget />
      <CustomerModeWidget />
      <DropdownRoot />
    </>
  );
};
