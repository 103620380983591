import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Fragment, ReactNode } from "react";
import { atom, useRecoilState } from "recoil";

export const OptionsAtom = atom<{
  title?: string;
  options: (
    | ReactNode
    | {
        label: string;
        onClick: () => void;
        theme?: "danger" | "default";
      }
  )[];
}>({
  key: "OptionsAtom",
  default: {
    title: "",
    options: [] as any[],
  },
});

export const OptionsCommonModal = () => {
  const [{ title, options }, setModal] = useRecoilState(OptionsAtom);
  return (
    <OptionsModal
      open={options.length > 0}
      onClose={() => setModal({ title: "", options: [] })}
      title={title}
      options={options}
    />
  );
};

export const OptionsModal = (props: {
  open: boolean;
  onClose: () => void;
  title?: string;
  options: (
    | ReactNode
    | {
        label: string;
        onClick: () => void;
        theme?: "danger" | "default";
      }
  )[];
}) => {
  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <ModalContent title={props.title || "Sélectionner une option"}>
        {props.options.map((o: any, i) =>
          o?.label ? (
            <Button
              key={i}
              theme={o.theme || "default"}
              size="lg"
              className={
                "w-full mb-2 " +
                (i === props.options?.length - 1 ? "sm:mb-0 " : "")
              }
              onClick={() => {
                props.onClose();
                setTimeout(() => o.onClick(), 100);
              }}
            >
              {o.label}
            </Button>
          ) : (
            <Fragment key={i}>{o}</Fragment>
          )
        )}
      </ModalContent>
    </Modal>
  );
};
