import { useRef } from "react";
import "./index.css";

export const SvgIconRender = (props: {
  src?: string;
  alt?: string;
  fallback?: string;
  contentClassName?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const updateBookRender = (img: HTMLImageElement) => {};

  return (
    <div className="icon_container">
      <div className={props.contentClassName + " relative bg-transparent"}>
        <img
          src={props.src}
          alt={props.alt || "Couverture"}
          onMouseEnter={(e) => {
            updateBookRender(e.target as HTMLImageElement);
          }}
          onLoad={(e) => {
            updateBookRender(e.target as HTMLImageElement);
            setTimeout(() => {
              containerRef.current?.classList?.remove("opacity-0");
            }, 100);
          }}
          onError={(e) => {
            (e.target as HTMLImageElement).src =
              props.fallback || "/medias/question-mark.svg";
            setTimeout(() => {
              containerRef.current?.classList?.remove("opacity-0");
            }, 100);
          }}
        />
      </div>
    </div>
  );
};
