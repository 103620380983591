import { subListDisplayer } from "@features/product-forms/sublists/sublist-displayer";
import { SublistModal } from "@molecules/sublist-modal";
import { useParams } from "react-router-dom";
import { atom } from "recoil";

export const SuppliersModalAtom = atom<{
  type: string;
  id: string;
  data: any[];
  readonly: boolean;
}>({
  key: "SuppliersModalAtom",
  default: {
    type: "",
    readonly: false,
    data: [],
    id: "",
  },
});

export const SuppliersModal = () => {
  const { type } = useParams<{ type: string }>();
  return (
    <SublistModal
      atom={SuppliersModalAtom}
      schema={subListDisplayer(type || "LIVR", "suppliers")}
    />
  );
};
