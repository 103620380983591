import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useCallback, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atom } from "recoil";

export const ScanActivatedAtom = atom({
  key: "ScanActivatedAtom",
  default: false,
});
let scanInputField: any | null = null;

export const useCloseScan = () => {
  const setScanOn = useSetRecoilState(ScanActivatedAtom);

  return useCallback(() => {
    scanInputField = null;
    setScanOn(false);
  }, [setScanOn]);
};

export const useScan = () => {
  const [scanOn, setScanOn] = useRecoilState(ScanActivatedAtom);
  const [cameraAvailable, setCameraAvailable] = useState(false);

  const checkAvailable = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setCameraAvailable(devices.some((device) => device.kind === "videoinput"));
  };
  useControlledEffect(() => {
    checkAvailable();
  }, []);

  const openScan = useCallback(() => {
    setScanOn(true);
  }, [setScanOn]);

  const closeScan = useCallback(() => {
    scanInputField = null;
    setScanOn(false);
  }, [setScanOn]);

  return {
    scanOn,
    cameraAvailable,
    openScan,
    closeScan,
    setInputField: (inputField: any | null) => {
      scanInputField = inputField;
    },
    getInputField: () => scanInputField,
  };
};
