import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/decoration-label";
import { Input } from "@atoms/input/input-text";
import { InfoSmall, Section, Subtitle } from "@atoms/text";
import { useState } from "react";
import toast from "react-hot-toast";
import { FullScreenPage } from "@atoms/layout/page";
import SingleCenterCard from "@atoms/layout/centered-card";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useForgot } from "@features/general/forgot-password/state/use-forgot";
import { validatePassword } from "@features/utils/format/strings";
import { PasswordRules } from "@features/settings/shortcuts/password-rules";

export const RecoverPasswordPage = () => {
  const [codeValid, setCodeValid] = useState(false);

  return (
    <div className="flex w-full grow flex-col bg-slate-50 dark:bg-slate-900 h-full">
      <FullScreenPage>
        <SingleCenterCard insetLogo>
          <>
            {!codeValid && (
              <RecoverCodeStep onSetCode={(code) => setCodeValid(code)} />
            )}
            {codeValid && <RecoverPasswordStep />}
          </>
        </SingleCenterCard>
      </FullScreenPage>
    </div>
  );
};

export const RecoverCodeStep = ({
  onSetCode,
}: {
  onSetCode: (codeValid: boolean) => void;
}) => {
  const { checkCode } = useForgot();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const next = async () => {
    setLoading(true);
    const res = await checkCode(code);
    try {
      if (res) {
        onSetCode(res);
      } else {
        toast.error("Code invalide");
      }
    } catch (e) {
      toast.error(t("connection.errors.server"));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="text-center">
        <Section>Récupération mot de passe</Section>
        <Subtitle>Rentrez le code reçu</Subtitle>
      </div>

      <InputLabel
        label={"Code de vérification"}
        className="mt-6"
        input={
          <Input
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        }
      />

      <div className="text-center mt-6">
        <Button
          size="lg"
          shortcut={["enter"]}
          loading={loading}
          onClick={async () => next()}
        >
          Valider code
        </Button>
      </div>
    </>
  );
};

export const RecoverPasswordStep = () => {
  const { setNewPwd } = useForgot();
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const next = async () => {
    setLoading(true);
    const result = await setNewPwd(password);
    try {
      if (result) {
        toast.success(t("Mot de passe change succes"));
        navigate(ROUTES.Login);
      } else {
        toast.error(t("mdp correspondent pas"));
      }
    } catch (e) {
      toast.error("An error occured");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="text-center">
        <Section>{t("Nouveau mot de passe")}</Section>
        <Subtitle>{t("Merci de saisir un nouveau mot de passe")}</Subtitle>
      </div>

      <InputLabel
        label={t("Nouveau mot de passe")}
        className="mt-6"
        input={
          <Input
            autoFocus
            value={password}
            type="password"
            placeholder="••••••••••"
            onChange={(e) => setPassword(e.target.value)}
          />
        }
      />

      <InputLabel
        label={t("account.password.confirmpassword")}
        className="mt-6"
        input={
          <Input
            autoFocus
            value={passwordCheck}
            type="password"
            placeholder="••••••••••"
            onChange={(e) => setPasswordCheck(e.target.value)}
          />
        }
      />

      <div className="text-center mt-6">
        <Button
          disabled={
            password === "" ||
            passwordCheck === "" ||
            !validatePassword(password) ||
            password !== passwordCheck
          }
          size="lg"
          shortcut={["enter"]}
          loading={loading}
          onClick={async () => next()}
        >
          {t("account.password.validate")}
        </Button>
        <div className="text-left mt-8">
          <InfoSmall>Règles pour le mot de passe : </InfoSmall>
          <ul className="list-disc">
            <div className="w-full ml-10">
              {PasswordRules.gPassword.rules.map((rule, index) => (
                <li key={index}>
                  <InfoSmall>{rule}</InfoSmall>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};
