import { Button } from "@atoms/button/button";
import Select from "@atoms/input/input-select";
import { Info } from "@atoms/text";
import { formatNumber } from "@features/utils/format/strings";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type PropsType = {
  dataLength: number;
  pagination: {
    total: number;
    page: number;
    perPage: number;
    orderBy?: number;
    order?: "ASC" | "DESC";
    moreButtonActiv?: boolean;
  };
  loading?: boolean;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (size: number) => void;
};

export function TablePaginationSimple({
  dataLength,
  loading,
  pagination,
  onChangePage,
}: PropsType) {
  const { t } = useTranslation();
  const bottomRef = useRef(null);
  const [freshRedirect, setFreshRedirect] = useState(true);
  useControlledEffect(() => {
    const timer = setTimeout(() => {
      setFreshRedirect(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useControlledEffect(() => {
    const currentRef = bottomRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        const isLastPage =
          pagination.page === Math.ceil(pagination.total / pagination.perPage);
        if (onChangePage && !isLastPage && !freshRedirect) {
          onChangePage(pagination.page + 1);
        }
      }
    });

    if (
      currentRef &&
      pagination.page < Math.ceil(pagination.total / pagination.perPage)
    ) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [pagination, loading]);

  const isDisabled = () => {
    if (pagination.moreButtonActiv === true) return false;
    if (
      loading ||
      pagination.page === Math.ceil(pagination.total / pagination.perPage) ||
      pagination.total === 0 ||
      pagination.total < 0 ||
      pagination.moreButtonActiv === false
    )
      return true;
  };

  return (
    <>
      <div className="flex w-full flex-col justify-center items-center space-y-2 my-2">
        <div className="items-center flex flex-row">
          {!!onChangePage && (
            <>
              <Button
                theme="default"
                size="sm"
                disabled={isDisabled()}
                onClick={() => {
                  return onChangePage && onChangePage(pagination.page + 1);
                }}
                className="!px-1.5 rounded-l-none shrink-0"
              >
                {t("general.tables.more")}
              </Button>
            </>
          )}
        </div>
        <div>
          <Info>
            {t("general.tables.pagination", {
              current: formatNumber(dataLength),
              total: pagination.total < 0 ? 0 : formatNumber(pagination.total),
            })}
          </Info>
        </div>
      </div>
      <div ref={bottomRef} style={{ height: "1px" }}></div>{" "}
    </>
  );
}

export function TablePagination({
  dataLength,
  loading,
  pagination,
  onChangePage,
  onChangePageSize,
}: PropsType) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex w-full items-center">
        <div className="grow">
          <Info>
            {t("general.tables.pagination", {
              current: formatNumber(dataLength),
              total: formatNumber(pagination.total),
            })}
          </Info>
        </div>
        <div className="items-center flex flex-row">
          {!!onChangePageSize && (
            <>
              <Info className="whitespace-nowrap mr-2">Per page</Info>
              <Select
                disabled={loading}
                size="sm"
                value={pagination.perPage || "10"}
                onChange={(e) =>
                  onChangePageSize && onChangePageSize(+e.target.value)
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
            </>
          )}
          {!!onChangePage && (
            <>
              <Button
                theme="default"
                size="sm"
                disabled={loading || pagination.page === 1}
                onClick={() => onChangePage && onChangePage(1)}
                className="!px-1.5 -mr-px ml-4 rounded-r-none shrink-0"
              >
                <ChevronDoubleLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                theme="default"
                size="sm"
                disabled={loading || pagination.page === 1}
                onClick={() =>
                  onChangePage && onChangePage(pagination.page - 1)
                }
                className="!px-1.5 -mr-px rounded-none shrink-0"
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                theme="default"
                size="sm"
                disabled={loading}
                className="-mr-px rounded-none pointer-events-none shrink-0"
              >
                {pagination.page}
              </Button>
              <Button
                theme="default"
                size="sm"
                disabled={
                  loading ||
                  pagination.page ===
                    Math.ceil(pagination.total / pagination.perPage)
                }
                onClick={() =>
                  onChangePage && onChangePage(pagination.page + 1)
                }
                className="!px-1.5 -mr-px rounded-none shrink-0"
              >
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                theme="default"
                size="sm"
                disabled={
                  loading ||
                  pagination.page ===
                    Math.ceil(pagination.total / pagination.perPage)
                }
                onClick={() =>
                  onChangePage &&
                  onChangePage(Math.ceil(pagination.total / pagination.perPage))
                }
                className="!px-1.5 rounded-l-none shrink-0"
              >
                <ChevronDoubleRightIcon className="h-4 w-4" />
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
