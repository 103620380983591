import { PageLoader } from "@atoms/layout/page-loader";
import { FinishedOrder } from "@components/order/finished-order";
import { useOrder } from "@features/customer/state/use-order";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const OrderPage = () => {
  const { id } = useParams<{ id: string }>();
  const { command, loading } = useOrder(id || "");

  useEffect(() => {
    if (command?.numCde)
      document.title = "Inférence - Commande " + command.numCde;
  }, [command]);

  if (loading) {
    return <PageLoader />;
  }
  if (command) {
    return <FinishedOrder command={command} />;
  }
};

export default OrderPage;
