import { useCallback, useEffect, useRef, useState } from "react";

export const useDragResize = (open: boolean) => {
  const enableResizing = false;
  const modalRef = useRef<HTMLDivElement>(null);

  const [draggingFrom, setDraggingFrom] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [resizeFrom, setResizeFrom] = useState<null | {
    x: number;
    y: number;
  }>(null);

  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [deltaSize, setDeltaSize] = useState<null | {
    x: number;
    y: number;
  }>(null);

  useEffect(() => {
    if (!open) {
      setDeltaPosition({ x: 0, y: 0 });
      setDeltaSize(null);
    }
  }, [open, setDeltaSize, setDeltaPosition]);

  const hdlDragMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDraggingFrom({
        x: e.clientX - deltaPosition.x,
        y: e.clientY - deltaPosition.y,
      });
    },
    [setDraggingFrom, deltaPosition]
  );

  const hdlResizeMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setResizeFrom({
        x: e.clientX,
        y: e.clientY,
      });
    },
    [setResizeFrom]
  );

  const hdlMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (draggingFrom) {
        setDeltaPosition({
          x: e.clientX - draggingFrom.x,
          y: e.clientY - draggingFrom.y,
        });
      }
      if (resizeFrom) {
        const currentWidth = modalRef.current?.clientWidth || 0;
        const currentHeight = modalRef.current?.clientHeight || 0;
        setResizeFrom({
          x: e.clientX,
          y: e.clientY,
        });
        setDeltaSize({
          x: currentWidth + (e.clientX - resizeFrom.x),
          y: currentHeight + (e.clientY - resizeFrom.y),
        });
      }
    },
    [draggingFrom, setDeltaPosition, resizeFrom, setDeltaSize, setResizeFrom]
  );

  const hdlMouseUp = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFrom(null);
    setResizeFrom(null);
  }, []);

  return {
    enableResizing,
    modalRef,
    draggingFrom,
    resizeFrom,
    deltaPosition,
    deltaSize,
    resetAll: () => {
      setDraggingFrom(null);
      setResizeFrom(null);
      setDeltaPosition({ x: 0, y: 0 });
      setDeltaSize(null);
    },
    hdlResizeMouseDown,
    hdlDragMouseDown,
    hdlMouseMove,
    hdlMouseUp,
  };
};
