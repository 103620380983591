import { fetchServer } from "@features/utils/fetch-server";

export class ForgotPwdClient {
  static getCode = async (refer: string) => {
    // --> En utilisant la variable "refer" qui est le mail ou le teléphone dans la requête
    const data = await fetchServer(`/user/${refer}/authenticate/forgot`, {
      reloadOn401: false,
    });
    if (data.status !== 204) {
      throw new Error("err");
    }
    return true;
  };

  static checkCode = async (refer: string, code: string) => {
    // --> En utilisant la variable "code" dans la requête

    const data = await fetchServer(
      `/user/${refer}/authenticate/forgot/validate?key=${code}`,
      { reloadOn401: false }
    );
    if (data.status !== 204) {
      throw new Error("err");
    }
    return true;
  };

  static setNewPwd = async (refer: string, code: string, pwd: string) => {
    const data = await fetchServer(
      `/user/${refer}/authenticate/forgot/password`,
      {
        method: "PUT",
        body: JSON.stringify({ key: code, pwd: pwd }),
        reloadOn401: false,
      }
    );
    if (data.status !== 204) {
      throw new Error("err");
    }
    return true;
  };
}
