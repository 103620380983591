import { Shortcut } from "@features/utils/shortcuts";

export const ShortcutMapping = {
  common: {
    confirm: "enter" as Shortcut,
    cancel: "esc" as Shortcut,
  },
  home: {
    search: "shift+k" as Shortcut,
  },
  products: {
    add_to_basket: "shift+a" as Shortcut,
    remove_from_basket: "shift+r" as Shortcut,
    open_basket: "shift+p" as Shortcut,
    start_client_order: "shift+c" as Shortcut,
    start_supplier_order: "shift+f" as Shortcut,
    start_supplier_return: "shift+r" as Shortcut,
    remove_basket: "shift+d" as Shortcut,
  },
};
