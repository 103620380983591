import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";

export const CommonConfirmModalAtom = atom<{
  open: boolean;
  title?: string;
  message?: string;
  icon?: any;
  theme?: "success" | "danger" | "warning" | "gray";
  confirmBtnText?: string;
  cancelBtnText?: string;
  onAccept?: () => void;
  onClose?: () => void;
}>({
  key: "ConfirmModalAtom",
  default: {
    open: false,
  },
});

export const ConfirmCommonModal = () => {
  const [modal, setModal] = useRecoilState(CommonConfirmModalAtom);

  const handleAccept = () => {
    if (modal.onAccept) {
      modal.onAccept();
    }
    setModal({ ...modal, open: false });
  };
  const handleClose = () => {
    if (modal.onClose) {
      modal.onClose();
    }
    setModal({ ...modal, open: false });
  };
  return (
    <ConfirmModal
      open={modal.open}
      title={modal.title}
      theme={modal.theme}
      icon={modal.icon}
      message={modal.message}
      confirmBtnText={modal.confirmBtnText}
      cancelBtnText={modal.cancelBtnText}
      onAccept={handleAccept}
      onClose={handleClose}
    />
  );
};

export const ConfirmModal = (props: {
  open: boolean;
  title?: string;
  theme?: "success" | "danger" | "warning" | "gray";
  message?: string;
  icon?: any;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onAccept?: () => void;
  onClose?: () => void;
}) => {
  return (
    <Modal open={props.open} onClose={() => props.onClose && props.onClose()}>
      <ModalContent
        title={props.title || "Confirmer l'action ?"}
        icon={props.icon}
        text={props.message || "Cliquez sur confirmer pour continuer."}
        buttons={
          <>
            <Button
              theme="primary"
              onClick={() => {
                setTimeout(() => {
                  props.onAccept && props.onAccept();
                }, 500);
              }}
              className="mr-4 my-2"
              shortcut={["enter"]}
            >
              {props.confirmBtnText || "Confirmer"}
            </Button>
            <Button
              onClick={(e) => {
                props.onClose && props.onClose();
              }}
              theme="default"
              className={"mr-4 my-2 shadow-none"}
              shortcut={["esc"]}
            >
              {props.cancelBtnText || "Annuler"}
            </Button>
          </>
        }
      />
    </Modal>
  );
};
