import { Page } from "@atoms/layout/page";
import { Subtitle, Title } from "@atoms/text";
import { ClientSelection } from "../../../components/client/select-client";
import { Frame } from "@atoms/layout/frame";
import { OrderHistory } from "../../../components/order/history";
import { useCustomer } from "@features/customer/state/use-customer";

export const ClientsPage = () => {
  const { loading, selectedCustomer, setSelectedCustomer } = useCustomer();

  return (
    <Page loading={loading}>
      <Title>Clients</Title>
      <div className="flex flex-col sm:flex-row justify-start h-full w-full gap-4 mt-3">
        <Frame className="md:w-1/4">
          <ClientSelection
            client={selectedCustomer}
            defined={selectedCustomer.numCli ? true : false}
            onChange={(newClient) => setSelectedCustomer(newClient)}
            readonly
            createDisabled={true}
          />
        </Frame>
        <Frame className="grow">
          <Subtitle className="mb-4">
            Historique des commandes de : {selectedCustomer.prnomCli}{" "}
            {selectedCustomer.nomCli}
          </Subtitle>
          {selectedCustomer.numCli && (
            <OrderHistory
              clientID={selectedCustomer.numCli || null}
              clientMail={selectedCustomer.addrMail}
              clientPhone={selectedCustomer.mobil}
            />
          )}
        </Frame>
      </div>
    </Page>
  );
};
