import { formatAmount } from "@features/utils/format/strings";
import _ from "lodash";
import { useCallback, useEffect, useState, useRef } from "react";
import { Input, InputProps } from "./input-text";

export const InputFormat = (
  props: InputProps & {
    format: "price" | "percentage" | "mail" | "phone";
    currency?: string;
    nbDecim?: number;
  }
) => {
  const [value, setValue] = useState(props.value);
  const [isFocused, setIsFocused] = useState(false);
  const [oldSelectedValue, setOldSelectedValue] = useState<string>();
  const ref = useRef<HTMLInputElement>(null);

  const extractRawValue = (val: string) => {
    if (!(val + "").trim()) return "";
    return (
      parseFloat(
        val
          .replace(",", ".")
          .replace(/[^0-9.-]/gm, "")
          .replace(/(.)-/gm, "$1")
      ) + ""
    );
  };

  const applyFormat = useCallback(
    (val: string) => {
      if (!(val + "").trim()) return "";
      if (props.format === "percentage") {
        val = parseFloat(val).toFixed(2).toString();
        val = extractRawValue(val) + " %";
      } else if (props.format === "price") {
        val =
          "" +
          formatAmount(
            parseFloat(extractRawValue(val)),
            props.currency,
            props.nbDecim
          );
      }
      return val;
    },
    [props.format, props.currency, props.nbDecim]
  );

  useEffect(() => {
    if (!isFocused) {
      setValue(applyFormat((props.value as string) + ""));
    }
  }, [isFocused, props.value, applyFormat]);

  return (
    <Input
      className="text-right"
      style={props.style}
      {..._.omit(props, "value", "onChange")}
      value={value}
      onChange={(e) => {
        let newValue = e.target.value;
        if (Number.isNaN(parseFloat(e.target.value))) {
          if (e.target.value !== "") return;
        }

        if (props.min === "0" || props.min === 0) {
        }
        if (
          (props.min === "0" || props.min === 0) &&
          parseFloat(e.target.value) < parseFloat(props.min.toString())
        ) {
          newValue = props.min.toString();
        }
        if (
          props.max &&
          parseFloat(e.target.value) > parseFloat(props.max.toString())
        ) {
          newValue = props.max.toString();
        }

        setValue(newValue);
        props.onChange &&
          props.onChange({
            target: { value: extractRawValue(newValue) },
          } as any);
      }}
      inputRef={ref}
      onFocus={(e) => {
        setIsFocused(true);
        setOldSelectedValue(e.target.value);
        props.onFocus && props.onFocus(e as any);
      }}
      onBlur={(e) => {
        setIsFocused(false);
        if (oldSelectedValue !== e.target.value)
          props.onBlur && props.onBlur(e as any);
      }}
    />
  );
};
