import { BookRender } from "@atoms/book";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";

export const ImageModalAtom = atom({
  key: "ImageModalAtom",
  default: { open: false, src: "", alt: "" },
});

export const ImageModal = () => {
  const [imageModal, setImageModal] = useRecoilState(ImageModalAtom);

  return (
    <Modal
      className="bg-red-400 sm:max-w-[900px]"
      open={imageModal.open}
      onClose={() => setImageModal({ ...imageModal, open: false })}
    >
      <ModalContent title={imageModal.alt}>
        <BookRender src={imageModal.src} alt={imageModal.alt} />
      </ModalContent>
    </Modal>
  );
};
