import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";

export const HtmlFormModal = atom({
  key: "HtmlFormModal",
  default: false,
});

export const HtmlFormModalText = atom({
  key: "HtmlFormModalText",
  default: "",
});

export const HtmlModal = () => {
  const [htmlModal, setHtmlModal] = useRecoilState(HtmlFormModal);
  const [htmlText, setHtmlText] = useRecoilState(HtmlFormModalText);

  return (
    <Modal
      className="bg-red-400 sm:max-w-[900px]"
      open={htmlModal}
      onClose={() => {
        setHtmlText("");
        setHtmlModal(false);
      }}
    >
      <ModalContent title={"Résumé"}>
        <Frame className="dark:text-gray-500">
          <div dangerouslySetInnerHTML={{ __html: htmlText.toString() }} />
        </Frame>
      </ModalContent>
    </Modal>
  );
};
