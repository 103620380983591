import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AccountApiClient } from "../api-client/api-client";

export const useAccount = () => {
  const { t } = useTranslation();

  const changePassword = async (oldPwd: string, pwd: string) => {
    let result = await AccountApiClient.updatePassword(oldPwd, pwd);
    if (result) {
      toast.success(t("general.success"));
    } else {
      toast.error(t("general.error"));
    }
    return result;
  };

  return {
    changePassword,
  };
};
