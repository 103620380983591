import { useAuth } from "@features/general/auth/state/use-auth";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import {
  getLocalStorage,
  localStorageBuildPrefixKey,
  setLocalStorage,
} from "@features/utils/local-storage";
import { useEffect, useState } from "react";
import { atom, AtomOptions, RecoilState, useRecoilCallback } from "recoil";

/** Use it over an atom to sync it over multiple tabs */
export const syncAtom = <T>(atom: AtomOptions<T> & { default: T }) => {
  const prefixedKey = "tab-sync-" + atom.key;
  const channel = new BroadcastChannel(prefixedKey);

  return {
    ...atom,
    default: atom.default || null,
    effects: [
      ({ setSelf, onSet }: any) => {
        // Existing onSet to broadcast the data to other tabs
        onSet((value: T) => {
          channel.postMessage(value);
        });

        // New code: Listen for changes from other tabs
        const syncTabs = (e: MessageEvent) => {
          setSelf(e.data);
        };

        channel.addEventListener("message", syncTabs);

        // Cleanup
        return () => {
          channel.removeEventListener("message", syncTabs);
          channel.close();
        };
      },
      ...(atom.effects || []),
    ],
  };
};

const persistedAtomsInitiators: {
  [key: string]: {
    status: string;
    type: "session" | "user";
    atom: RecoilState<any>;
  };
} = {};

/** Use it over an atom to persist it */
export const persistAtom = <T>(
  type: "session" | "user",
  options: AtomOptions<T>
) => {
  const tmp = atom<T>({
    ...options,
    effects: [
      ({ onSet }: any) => {
        // Existing onSet to persist the data
        onSet((value: T) => {
          setLocalStorage(type, options.key, value);
        });
      },
      ...(options.effects || []),
    ],
    default: (options as any).default,
  });

  if (!persistedAtomsInitiators[options.key]) {
    persistedAtomsInitiators[options.key] = {
      status: "",
      type,
      atom: tmp,
    };
  }

  return tmp;
};

export const usePersistedAtoms = () => {
  const { user } = useAuth();
  const { current } = useShopLocations();
  const setPersistedAtoms = useRecoilCallback(
    ({ set }) =>
      () => {
        for (const key in persistedAtomsInitiators) {
          const type = persistedAtomsInitiators[key].type;
          const prefix = localStorageBuildPrefixKey(type, key);
          if (persistedAtomsInitiators[key].status === prefix) continue;
          persistedAtomsInitiators[key].status = prefix;
          const value = getLocalStorage(type, key);
          if (value) set(persistedAtomsInitiators[key].atom, value);
        }
      },
    [user?.id, current?.codeLieu]
  );

  useEffect(() => {
    if (user?.id && current?.codeLieu) {
      setPersistedAtoms();
    }
  }, [setPersistedAtoms, user?.id, current?.codeLieu]);
};

export const usePersistedState = <T>(
  type: "session" | "user",
  key: string,
  initialState: T
) => {
  const [state, setState] = useState<T>(
    getLocalStorage(type, key) || initialState
  );

  useEffect(() => {
    setLocalStorage(type, key, state);
  }, [key, type, state]);

  return [state, setState] as const;
};
