import "@features/utils/i18n";
import ReactDOM from "react-dom/client";
import initReactFastclick from "react-fastclick";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import reportWebVitals from "./reportWebVitals";
import InitialRouter from "./views";
import ScanProvider from "@views/scan-provider";
import NavigationProvider from "@views/navigation-provider";
initReactFastclick();

const App = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <NavigationProvider>
          <ScanProvider>
            <InitialRouter />
          </ScanProvider>
        </NavigationProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

reportWebVitals();
