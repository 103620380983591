import _ from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";
import "./index.scss";
import { Column, Pagination, RenderOptions, RenderedTable } from "./table";
type PropsType<T> = {
  name?: string;
  anchorPos?: number;
  columns: Column<T>[];
  data: T[];
  scrollable?: boolean;
  rowIndex?: string;
  total?: number;
  switchMode?: boolean;
  onChangeMode?: (v: boolean) => void;
  orderables?: string[];
  grid?: boolean;
  cellClassName?: (row: T) => string;
  gridClassName?: string;
  className?: string;
  loading?: boolean;
  onRequestData?: (pagination: Pagination) => Promise<void>;
  onFetchExportData?: (pagination: Pagination) => Promise<any[]>;
  customChangeOrder?: (pagination: Pagination) => Promise<void>;
  exportDefaultHeader?: string[];
  exportUniqKey?: string;
  subObjectHeader?: { [key: string]: string[] };
  onResetPagination?: () => void;
  emptyTabText?: string;
  moreButtonActiv?: boolean;
  headerPagination?: boolean;
  forceGridResponsive?: boolean;
  columnsSeparators?: boolean;
  codePres?: string;

  onClick?: (item: T) => void;
  onSelect?:
    | {
        icon?: (props: any) => JSX.Element;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  showPagination?: false | "simple" | "full" | true;
  initialPagination?: Pick<
    Pagination,
    "order" | "orderBy" | "page" | "perPage"
  >;
};

export function Grid<T>(
  props: Omit<PropsType<T>, "grid" | "columns"> & {
    render: (item: T, options?: RenderOptions) => string | ReactNode;
    orderables?: string[];
  }
) {
  return (
    <Table
      grid
      {..._.omit(props, "render")}
      columns={[
        {
          orderable: !!props.orderables?.length,
          title: props.orderables?.[0],
          render: props.render,
        },
        ...(props.orderables || []).slice(1).map((key) => ({
          orderable: true,
          title: key,
          render: () => "",
        })),
      ]}
    />
  );
}

export function Table<T>({
  name,
  columns,
  data,
  rowIndex,
  total,
  scrollable,
  showPagination,
  initialPagination,
  onRequestData,
  onFetchExportData,
  exportDefaultHeader,
  exportUniqKey,
  subObjectHeader,
  onClick,
  onSelect,
  loading,
  grid,
  cellClassName,
  gridClassName,
  className,
  switchMode,
  onChangeMode,
  anchorPos,
  orderables,
  emptyTabText,
  moreButtonActiv,
  headerPagination,
  columnsSeparators,
  forceGridResponsive,
  customChangeOrder,
  onResetPagination,
  codePres,
}: PropsType<T>) {
  const [pagination, setPagination] = useState<Pagination>({
    total: total || 0,
    page: initialPagination?.page || 1,
    perPage: initialPagination?.perPage || 10,
    orderBy: initialPagination?.orderBy,
    order: initialPagination?.order,
  });
  const [internalLoading, setLoading] = useState(false);
  const [allColumns, setColumns] = useState(columns);

  const resolve = useCallback(async () => {
    setLoading(true);
    if (customChangeOrder) await customChangeOrder(pagination);
    if (onRequestData) await onRequestData(pagination);
    setLoading(false);
  }, [onRequestData, setLoading, pagination, customChangeOrder]);

  /* react-hooks/exhaustive-deps issues */
  (useEffect as any)(() => {
    if (total !== pagination.total) {
      setPagination({
        ...pagination,
        page: 1,
        total: !total || total < 0 ? 0 : total,
      });
    }
  }, [total, setPagination]);

  /* react-hooks/exhaustive-deps issues */
  (useEffect as any)(() => {
    resolve();
  }, [
    pagination.perPage,
    pagination.page,
    pagination.order,
    pagination.orderBy,
  ]);

  //When columns changes --> check if they're the same
  //If it isn't the same, we set the order to 0 so no index pb
  (useEffect as any)(() => {
    if (!areColumnsEqual(columns, allColumns)) {
      setColumns(columns);
      setPagination({ ...pagination, order: "DESC", orderBy: 0 });
    }
  }, [columns]);

  return (
    <RenderedTable
      name={name}
      anchorPos={anchorPos}
      columns={columns}
      data={data}
      rowIndex={rowIndex}
      showPagination={showPagination}
      pagination={pagination}
      scrollable={scrollable}
      onClick={onClick}
      onSelect={onSelect}
      loading={loading || internalLoading}
      grid={grid}
      switchMode={switchMode}
      onChangeMode={onChangeMode}
      cellClassName={cellClassName}
      emptyTabText={emptyTabText}
      gridClassName={gridClassName}
      className={className}
      orderables={orderables}
      onFetchExportData={onFetchExportData}
      exportDefaultHeader={exportDefaultHeader}
      exportUniqKey={exportUniqKey}
      subObjectHeader={subObjectHeader}
      moreButtonActiv={moreButtonActiv}
      headerPagination={headerPagination}
      columnsSeparators={columnsSeparators}
      codePres={codePres}
      onChangeOrder={(columnIndex, direction) => {
        setPagination({
          ...pagination,
          orderBy: columnIndex,
          order: direction,
          page: 1,
        });
      }}
      onChangePage={(page) => {
        setPagination({
          ...pagination,
          page,
        });
      }}
      onChangePageSize={(size) => {
        setPagination({
          ...pagination,
          perPage: size,
        });
      }}
    />
  );
}

//To check if all columns are the same
function areColumnsEqual(array1: string | any[], array2: string | any[]) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].title !== array2[i].title) {
      return false;
    }
  }

  return true;
}
