import { atom, atomFamily } from "recoil";
import { Client, Order } from "../type";

export const ProductAtom = atomFamily<Order | null, string>({
  key: "OrderAtom",
  default: null,
});

export const SelectedCustomer = atom<Client>({
  key: "SelectedCustomer",
  default: { numCli: "" } as Client,
});

export const CustomerMode = atom({
  key: "CustomerMode",
  default: false,
});
