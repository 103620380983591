import { InfoSmallBold } from "@atoms/text";

const Ribbon = (props: { text?: string; className?: string }) => {
  return (
    <div
      className={`flex justify-start w-20 px-1 triangle-cutout ${props.className}`}
    >
      <InfoSmallBold className="text-left">
        {props.text ? props.text : "ruban"}
      </InfoSmallBold>
    </div>
  );
};

export default Ribbon;
