import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Title, Subtitle } from "@atoms/text";
import { ROUTES } from "@features/routes";
import { useNavigate } from "react-router-dom";

export const NotAvailablePage = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <div className="grow flex flex-col gap-6 justify-center content-center text-center my-40">
        <Title>Indisponible</Title>
        <Subtitle>
          Ce produit ou cette page n'est pas disponible pour le moment.
        </Subtitle>
        <div className="grow">
          <Button
            className=""
            onClick={() => {
              navigate(ROUTES.Home);
            }}
          >
            Retourner sur l'accueil
          </Button>
        </div>
      </div>
    </Page>
  );
};
