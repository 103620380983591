export const PasswordRules = {
  gPassword: {
    security: true,

    default: {
      minChar: 8,

      maxChar: 30,

      ctrl: "(.{8,30})",
    },

    secure: {
      minChar: 8,

      maxChar: 30,

      ctrl: '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+_\\-=}{[\\]|:;"/?.><,`~]).{8,30})',
    },
    rules: [
      "8 caractères minimum",
      "30 caractères maximum",
      "Doit contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial",
    ],
  },
};
