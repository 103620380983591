import { Checkbox } from "@atoms/input/input-checkbox";
import { BaseBoldSmall } from "@atoms/text";
import {
  ArrowSmRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ReactNode } from "react";

export type CardProps<T> = {
  className?: string;
  title?: string;
  children: ReactNode;
  checkable?: boolean;
  item?: T;
  onInfoClick: (item: T) => void;
};

export const GenericCard = <T,>({ ...props }: CardProps<T>) => {
  return (
    <div className="relative hover:cursor-pointer">
      <div
        className="group flex flex-col h-26 rounded-lg relative"
        onClick={() => {}}
      >
        <div
          className={
            "flex flex-row h-8 justify-start rounded-lg " + props.className
          }
        >
          {props.checkable && (
            <div className="flex flex-col grow justify-center items rounded-lg">
              <Checkbox className="border-slate-500 mx-2 rounded-lg" />
            </div>
          )}
          <div
            className={`flex flex-col grow justify-center rounded-lg ${
              props.checkable ? "" : "mx-4"
            }`}
          >
            <BaseBoldSmall className="group-hover:underline hover:cursor-pointer">
              {props.title}
            </BaseBoldSmall>
          </div>
        </div>
        <div className="flex flex-col h-full w-full mx-4 my-2 rounded-lg">
          {" "}
          {props.children}
        </div>
        <div className="absolute bg-primary p-1 rounded-tl-lg rounded-br-lg bottom-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100 hover:cursor-pointer">
          <ArrowSmRightIcon className="w-5 h-5 group-hover:w-6 group-hover:h-6" />
        </div>
      </div>
      <div
        id="info"
        className="absolute bg-primary p-1 rounded-tr-lg rounded-bl-lg top-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100"
        onClick={(e) => {
          e.stopPropagation();
          if (props.item) props.onInfoClick(props.item);
        }}
      >
        <InformationCircleIcon className="w-5 h-5 hover:w-6 hover:h-6" />
      </div>
    </div>
  );
};
