import { useEffect, useState } from "react";

export const useDomElement = (elementId: string) => {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const findElement = () => {
      const element = document.getElementById(elementId);
      if (element) {
        setElementRef(element);
      } else {
        setTimeout(findElement, 1000); // Retry every second
      }
    };

    findElement();
  }, [elementId]);

  return elementRef;
};
