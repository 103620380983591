export const ScanWidgetDomId = "scan-widget";
export const ScanTriggerIconDomId = "scan-trigger-widget";

export const ScanBox = () => {
  return <div id={ScanWidgetDomId} />;
};

export const ScanTriggerIcon = () => {
  return <div id={ScanTriggerIconDomId} />;
};
