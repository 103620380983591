import { Page } from "@atoms/layout/page";
import { Base, Section, Title } from "@atoms/text";
import { useState } from "react";
import { Button } from "@atoms/button/button";
import { FolderOpenIcon } from "@heroicons/react/outline";
import { FaClockRotateLeft } from "react-icons/fa6";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Column } from "@molecules/table/table";
import { SupplierOrder } from "@features/supplier/types";
import { formatTime } from "@features/utils/format/dates";
import { Table } from "@molecules/table";

export const SupplierOrderPage = () => {
  const {
    loading,
    supplierOrderList,
    changeSupplierOrderList,
    getFournOrders,
  } = useSuppliers();
  const [history, setHistory] = useState(false);

  //   const [gridMode, setGridMode] = usePersistedState(
  //     "user",
  //     "reception-all-grid-mode",
  //     {
  //       activated: false,
  //     }
  //   );

  //   const [currentOrder, setCurrentOrder] = usePersistedState<any>(
  //     "user",
  //     "reception-all-order",
  //     {
  //       orderBy: 0,
  //       orderDir: "DESC",
  //     }
  //   );

  //   const [numPage, setNumPage] = usePersistedState(
  //     "user",
  //     "reception-all-num-page",
  //     1
  //   );

  //   const navigate = useNavigate();

  const columns: Column<SupplierOrder>[] = [
    {
      id: "codeFourn",
      title: "Fournisseur",
      render: (p) => p.codeFourn,
    },
    {
      id: "numCde",
      title: "Num. Cde",
      render: (p) => p.numCde,
    },
    {
      id: "panie",
      title: "Panier",
      render: (p) => p.panie,
    },
    {
      id: "codeLieuLivr",
      title: "Livré à",
      render: (p) => p.codeLieuLivr,
    },
    {
      id: "codeModeExpe",
      title: "Exp",
      render: (p) => p.codeModeExpe,
    },
    {
      id: "dateCde",
      title: "Date cde",
      render: (p) => (
        <Base className="whitespace-nowrap">
          {formatTime(p.dateCde as string, "fr-FR", {
            keepTime: new Date(p.dateCde).getSeconds() === 0 ? false : true,
            keepSeconds: false,
            keepDate: true,
          })}
        </Base>
      ),
    },
    {
      id: "dateLivrPrev",
      title: "Livr. prev.",
      render: (p) => (
        <Base className="whitespace-nowrap">
          {formatTime(p.dateLivrPrev as string, "fr-FR", {
            keepTime:
              new Date(p.dateLivrPrev).getSeconds() === 0 ? false : true,
            keepSeconds: false,
            keepDate: true,
          })}
        </Base>
      ),
    },
    {
      id: "trans",
      title: "Transmission",
      render: (p) => p.trans,
    },
    {
      id: "statuCde",
      title: "Statut",
      render: (p) => p.statuCde,
    },
    {
      id: "dateDernStatu",
      title: "Date Statut",
      render: (p) => (
        <Base className="whitespace-nowrap">
          {formatTime(p.dateDernStatu as string, "fr-FR", {
            keepTime:
              new Date(p.dateDernStatu).getSeconds() === 0 ? false : true,
            keepSeconds: false,
            keepDate: true,
          })}
        </Base>
      ),
    },
    {
      id: "qte",
      title: "Qte",
      render: (p) => <></>,
    },
    {
      id: "nbRef",
      title: "Nbr ref.",
      render: (p) => p.nbRef,
    },
    {
      id: "nbArticle",
      title: "Nbr articles",
      render: (p) => p.nbArticle,
    },
    {
      id: "refer",
      title: "Référence",
      render: (p) => p.refer,
    },
    {
      id: "edi",
      title: "N° lot EDI",
      render: (p) => p.edi,
    },
    {
      id: "numCdeEdi",
      title: "Num. Cde EDI",
      render: (p) => p.numCdeEdi,
    },
    {
      id: "qteRecu",
      title: "Qte reçu",
      render: (p) => <></>,
    },
    {
      id: "nbrCdeClit",
      title: "Nbr cde clit",
      render: (p) => <></>,
    },
    {
      id: "mtBrut",
      title: "Mt. brut",
      render: (p) => <></>,
    },
    {
      id: "mtNet",
      title: "Mt. net",
      render: (p) => <></>,
    },
  ];

  return (
    <Page loading={loading}>
      <div className="w-full flex justify-between">
        <Title>Commandes fournisseurs</Title>
        <Button
          disabled={loading}
          theme={history ? "outlined" : "primary"}
          onClick={() => {
            setHistory(!history);
          }}
          icon={({ className }) => {
            return history ? (
              <FolderOpenIcon className={className} />
            ) : (
              <FaClockRotateLeft className={className} />
            );
          }}
          data-tooltip={
            history
              ? "Voir les commandes fournisseurs en cours"
              : "Voir l'historique de commandes"
          }
        >
          {history ? "En cours" : "Historique"}
        </Button>
      </div>

      <div className="grow overflow-hidden">
        <Section>
          {history
            ? "Historique des commandes"
            : "Liste des commandes en cours"}
        </Section>
        <Table
          key={`${history}`}
          data={supplierOrderList.items}
          codePres="supplier"
          //onChangeMode={(v) => setGridMode({ activated: v })}
          showPagination={"full"}
          cellClassName={() => "rounded-lg"}
          initialPagination={{
            perPage: 15,
            page: 1,
            order: "DESC",
            orderBy: 0,
          }}
          onClick={async () => {}}
          exportDefaultHeader={[]}
          columns={columns}
          total={supplierOrderList.pagination.totalRows}
          orderables={["Date", "Fournisseur"]}
          onRequestData={async ({ page, perPage, order, orderBy }) => {
            const res = await getFournOrders(
              { clot: history },
              {
                orderBy: "dateCde",
                orderDir: order,
                pageNumber: page,
                pageSize: perPage,
              }
            );
            changeSupplierOrderList(res.items, res.pagination);
          }}
          gridClassName={
            "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          }
        />
      </div>
    </Page>
  );
};
