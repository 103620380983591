import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/decoration-label";
import { Input } from "@atoms/input/input-text";
import { FullScreenPage } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Section, Subtitle } from "@atoms/text";
import { PageLoader } from "@atoms/layout/page-loader";
import SingleCenterCard from "@atoms/layout/centered-card";
import { useAuth } from "@features/general/auth/state/use-auth";
import { ROUTES } from "@features/routes";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForgot } from "@features/general/forgot-password/state/use-forgot";
import { useSetRecoilState } from "recoil";
import { AccountPasswordModalAtom } from "@views/client/account/password-change-modal";
import { validatePassword } from "@features/utils/format/strings";
import { InactivModalAtom } from "@views/client/account/inactiv-modal";

export const LoginPage = () => {
  const { loading: authLoading } = useAuth();
  const { changeLoginRefer } = useForgot();
  const [email, setEmail] = useState("");
  const setInactivModal = useSetRecoilState(InactivModalAtom);

  useEffect(() => {
    changeLoginRefer(email);
  }, [email, changeLoginRefer]);

  useEffect(() => {
    setInactivModal(false);
  }, [setInactivModal]);
  return (
    <div className="flex w-full grow flex-col bg-slate-50 dark:bg-slate-900 h-full">
      <FullScreenPage>
        <SingleCenterCard insetLogo>
          {authLoading && <PageLoader />}
          {!authLoading && (
            <>
              {!email && (
                <LoginIdStep onSetEmail={(email) => setEmail(email)} />
              )}
              {email && <LoginPasswordStep email={email} />}
            </>
          )}
        </SingleCenterCard>
      </FullScreenPage>
    </div>
  );
};

export const LoginIdStep = ({
  onSetEmail,
}: {
  onSetEmail: (email: string) => void;
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const next = async () => {
    setLoading(true);
    onSetEmail(email);
    setLoading(false);
  };

  return (
    <>
      <div className="text-center">
        <Section>{t("connection.login.welcome")}</Section>
        <Subtitle>{t("connection.login.infos")}</Subtitle>
      </div>

      <InputLabel
        label={t("connection.login.label")}
        className="mt-6"
        input={
          <Input
            type="email"
            autoComplete="inference-username"
            aria-label={t("connection.login.label")}
            autoCapitalize="none"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        }
      />

      <div className="text-center mt-6">
        <Button
          size="lg"
          shortcut={["enter"]}
          loading={loading}
          onClick={async () => next()}
        >
          {t("connection.login.button")}
        </Button>
      </div>
    </>
  );
};

export const LoginPasswordStep = ({ email }: { email: string }) => {
  const { login } = useAuth();
  const { getCode } = useForgot();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const setAccountPasswordModal = useSetRecoilState(AccountPasswordModalAtom);

  const { t } = useTranslation();

  const next = async () => {
    try {
      setLoading(true);
      await login(email, password);
      if (!validatePassword(password))
        setAccountPasswordModal({ open: true, forced: true, v: password });
    } catch (e) {
      if ((e as Error).message === "401")
        toast.error(t("connection.password.error"));

      //else toast.error((e as Error).message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="text-center">
        <Section>
          {t("connection.password.welcome")} {email}
        </Section>
        <Subtitle>{t("connection.password.infos")}</Subtitle>
      </div>

      <InputLabel
        label={t("connection.password.label")}
        className="mt-6"
        input={
          <Input
            autoFocus
            value={password}
            type="password"
            autoComplete="inference-password"
            aria-label={t("connection.password.label")}
            autoCapitalize="none"
            placeholder=""
            onChange={(e) => setPassword(e.target.value)}
          />
        }
      />

      <div className="text-center mt-6">
        <Button
          size="lg"
          shortcut={["enter"]}
          loading={loading}
          onClick={async () => next()}
        >
          Ouvrir Inférence
        </Button>
      </div>

      <div className="text-center mt-6">
        <Link
          className="dark:text-sky-800 dark:hover:text-sky-900"
          onClick={() => document.location.reload()}
        >
          {t("connection.back")}
        </Link>
        <br />
        {true && (
          <Link
            className="dark:text-sky-800 dark:hover:text-sky-900"
            to={ROUTES.Recover}
            onClick={() => {
              getCode(email);
            }}
          >
            {t("connection.password.newpassword")}
          </Link>
        )}{" "}
      </div>
    </>
  );
};
