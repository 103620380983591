import { useRecoilState } from "recoil";
import { SupplierApiClient } from "../api-client/api-client";
import {
  CommandType,
  ReturnType,
  SupplierOrder,
  SupplierOrderRequestFilter,
} from "../types";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import { SupplierOrderList } from "./store";

export const useSuppliers = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useOrder", false])
  );
  const [supplierOrderList, setSupplierOrderList] =
    useRecoilState(SupplierOrderList);
  const getMotif = async () => {
    return await SupplierApiClient.getMotifRefus();
  };

  const getModesExpedition = async () => {
    return await SupplierApiClient.getModesExpedition();
  };

  const getFournBl = async (
    codeFourn?: string,
    numBL?: string,
    recepOuver?: boolean,
    recepTerm?: boolean
  ) => {
    return await SupplierApiClient.getFournBl(
      codeFourn,
      numBL,
      recepOuver,
      recepTerm
    );
  };

  const getFournBlLines = async (
    codeFourn?: string,
    numBL?: string,
    numColis?: string,
    idColis?: string,
    livrNbRef?: number,
    livrNb?: number
  ) => {
    return await SupplierApiClient.getFournBlLines(
      codeFourn,
      numBL,
      numColis,
      idColis,
      livrNbRef,
      livrNb
    );
  };

  const getFournOrders = async (
    filters: SupplierOrderRequestFilter,
    pagination?: CommonPaginationRequest
  ) => {
    return await SupplierApiClient.getFournOrders(filters, pagination);
  };

  const getFournOrderLines = async (numCde: string) => {
    return await SupplierApiClient.getFournOrderLines(numCde);
  };

  const changeSupplierOrderList = (
    newOrderList: SupplierOrder[],
    pagination?: CommonPagination
  ) => {
    setSupplierOrderList({
      pagination: pagination ? pagination : supplierOrderList.pagination,
      items: newOrderList,
    });
  };

  const createRetour = async (retour: ReturnType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createRetour(retour, codeLieu);
    setLoading(false);
    return res;
  };

  const createOrder = async (order: CommandType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createOrder(order, codeLieu);
    setLoading(false);
    return res;
  };

  const getReassortLines = async (
    modeGestion: string,
    codeTypeProduct?: string,
    pagination?: CommonPaginationRequest
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.getReassortLines(
        modeGestion,
        codeTypeProduct,
        pagination
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const updateReassortLine = async (id: string, decis: string) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.updateReassortLine(id, decis);
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      return false;
    }
  };

  return {
    loading,
    supplierOrderList,
    changeSupplierOrderList,
    getFournBl,
    getFournBlLines,
    getFournOrders,
    getFournOrderLines,
    getMotif,
    getModesExpedition,
    createRetour,
    createOrder,
    getReassortLines,
    updateReassortLine,
  };
};
