import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ProductType } from "../types";
import { useProduct } from "./use-product";

export const useProductEditor = (ean: string) => {
  const { product } = useProduct(ean);
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState<Partial<ProductType>>(product || {});

  useEffect(() => {
    if (product) {
      setDraft(product);
    }
  }, [product]);

  const cancel = () => {
    setDraft(product || {});
  };

  const save = async () => {
    setLoading(true);
    try {
      //TODO update product
      throw new Error("Not implemented");
      // await refresh();
      // toast.success("Produit mis à jour");
    } catch (err) {
      toast.error(`${err}`);
    }
    setLoading(false);
  };

  return { draft, setDraft, save, cancel, loading };
};
