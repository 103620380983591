import { registerScanCallback } from "@atoms/input-scan/scanner";
import { useCloseScan } from "@atoms/input-scan/use-scan";
import { OptionsAtom } from "@molecules/options-modal";
import { ROUTES } from "@features/routes";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import { nanoid } from "nanoid";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { SelectedBasketId } from "./store";
import { useSetBaskets } from "./use-baskets-setter";
import { useProducts } from "./use-products";
import { ProductTypeShort } from "../types";

//Special actions available from the home page or anywhere (no state, only setters)
export const useBasketsActions = () => {
  const openOptions = useSetRecoilState(OptionsAtom);
  const closeScan = useCloseScan();
  const { addBasket, updateBasket } = useSetBaskets();
  const { getProductScan } = useProducts();
  const navigate = useNavigate();

  //Create a basket with products inside and open it
  const createBasket = useCallback(
    async (
      content: { ean: string; count: number }[],
      options?: { mergeWith: string }
    ) => {
      toast.success("Création du panier...");
      const id = nanoid();
      addBasket({
        created_at: Date.now(),
        id,
        products: await Promise.all(
          content.map(async (a) => ({
            product: await getProductScan(a.ean) as ProductTypeShort,
            count: a.count,
          }))
        ),
      });
      navigate(ROUTES.Basket.replace(":id", options?.mergeWith || id));
    },
    [addBasket, navigate, getProductScan]
  );

  const importBasket = useRecoilCallback(
    ({ snapshot }) =>
      (content: { ean: string; count: number }[]) => {
        const currentBasket = snapshot.getLoadable(SelectedBasketId).getValue();

        openOptions({
          title: "Importer un panier",
          options: [
            {
              label: "Ajouter au panier courant",
              onClick: async () => {
                toast.success("Importation du panier...");
                updateBasket(currentBasket, {
                  add: await Promise.all(
                    content.map(async (a) => ({
                      product: await getProductScan(a.ean) as ProductTypeShort,
                      count: a.count,
                    }))
                  ),
                });
                navigate(ROUTES.Basket.replace(":id", currentBasket));
              },
            },
            {
              label: "Nouveau panier",
              onClick: () => {
                createBasket(content);
              },
            },
          ],
        });
      }
  );

  useGlobalEffect(
    "useBasketsActions",
    () => {
      registerScanCallback(async (text) => {
        try {
          const content = JSON.parse(text);
          if (content.type === "basket") {
            importBasket(
              content.content.map((a: any) => ({
                ean: a[0],
                count: a[1],
              }))
            );
            closeScan();
            return true;
          }
        } catch (e) {
          //Not a basket
        }
        return false;
      });
    },
    []
  );

  return {
    createBasket,
  };
};
