import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ProductsBasketsAtom, SelectedBasketId } from "./store";
import { useSetBaskets } from "./use-baskets-setter";

export const useBaskets = () => {
  const productsBaskets = useRecoilValue(ProductsBasketsAtom);
  const [currentBasket, setCurrentBasket] = useRecoilState(SelectedBasketId);
  const { addBasket, removeBasket, updateBasket, changeBasketCustomer } =
    useSetBaskets();

  const getBasket = useCallback(
    (id: string) => {
      return productsBaskets.find((i) => i.id === id);
    },
    [productsBaskets]
  );

  const getProductsCount = (id: string) => {
    return (
      getBasket(id)?.products.reduce(
        (sum, el) => (!el.aside ? sum + el.count : sum),
        0
      ) || 0
    );
  };

  const getProductsPrice = (id: string) => {
    return (
      getBasket(id)?.products.reduce(
        (sum, el) => (!el.aside ? sum + el.count * el.product.prixVente : sum),
        0
      ) || 0
    );
  };

  useEffect(() => {
    const defaultId = productsBaskets.find((e) => e.products.length === 0)?.id;
    if (!productsBaskets.find((e) => e.id === currentBasket) && defaultId) {
      setCurrentBasket(defaultId);
    }
  }, [currentBasket, productsBaskets, setCurrentBasket]);

  return {
    baskets: productsBaskets,
    current: currentBasket,
    setCurrent: setCurrentBasket,
    removeBasket,
    addBasket,
    updateBasket,
    getBasket,
    getProductsCount,
    getProductsPrice,
    changeBasketCustomer,
  };
};
