export class PaperSublist {
  static suppliers = [
    {
      key: "codeFourn",
      label: "Code fournisseur",
      type: "text",
      options: async () => {
        return [{ label: "option 1", value: "opt1" }];
      },
    },
    {
      key: "raisSocial",
      label: "Raison sociale",
      type: "text",
    },
    {
      key: "eaN13",
      label: "EAN13",
      type: "scan",
    },
    {
      key: "princ",
      label: "Principal",
      type: "boolean",
    },
    {
      key: "refComme",
      label: "Référence commerciale",
      type: "text",
    },
    {
      key: "codeDispo",
      label: "Code disponibilité",
      type: "text",
    },
    {
      key: "libDispo",
      label: "Libellé disponibilité",
      type: "text",
    },
    {
      key: "dateNouvDispo",
      label: "Date nouvelle disponibilité",
      type: "date",
    },
    {
      key: "par",
      label: "Par",
      type: "number",
    },
    {
      key: "prixAchatBase1",
      label: "Prix d'achat de base 1",
      type: "formatted",
      format: "price",
    },
    {
      key: "prixAchatBase2",
      label: "Prix d'achat de base 2",
      type: "formatted",
      format: "price",
    },
    {
      key: "codeModeExpe",
      label: "Code mode d'expédition",
      type: "text",
    },
    {
      key: "actif",
      label: "Actif",
      type: "boolean",
    },
    {
      key: "indicRupt",
      label: "Indicateur de rupture",
      type: "boolean",
    },
    {
      key: "prixVenteConsHt",
      label: "Prix de vente consommateur HT",
      type: "number",
    },
    {
      key: "dateAppliPA",
      label: "Date d'application du PA",
      type: "date",
    },
    {
      key: "suppr",
      label: "Supprimé",
      type: "boolean",
    },
    {
      key: "parVente",
      label: "Par vente",
      type: "number",
    },
    {
      key: "qteParCart",
      label: "Quantité par carton",
      type: "number",
    },
    {
      key: "qteParPalet",
      label: "Quantité par palette",
      type: "number",
    },
    {
      key: "qteParPaqu",
      label: "Quantité par paquet",
      type: "number",
    },
    {
      key: "codeBD",
      label: "Code BD",
      type: "text",
      disabled: true,
    },
    {
      key: "dateCreat",
      label: "Date de création",
      type: "date",
      disabled: true,
    },
    {
      key: "dateMAJ",
      label: "Date de mise à jour",
      type: "date",
      disabled: true,
    },
    {
      key: "utilCreat",
      label: "Utilisateur de création",
      type: "text",
      disabled: true,
    },
    {
      key: "utilMAJ",
      label: "Utilisateur de mise à jour",
      type: "text",
      disabled: true,
    },
  ];
  static conditionAchat = [
    { key: "codeFourn", label: "Code fournisseur", type: "text" },
    { key: "codeFab", label: "Code fabricant", type: "text" },
    { key: "codeFamil", label: "Famille", type: "text" },
    { key: "codeSousFamil", label: "Sous-famille", type: "text" },
    { key: "codeSegment", label: "Segment", type: "text" },
    { key: "idBd", label: "Id BD", type: "text" },
    {
      key: "tauxNet",
      label: "Taux net",
      type: "formatted",
      format: "percentage",
    },
    {
      key: "tauxNetNet",
      label: "Taux net",
      type: "formatted",
      format: "percentage",
    },
    {
      key: "prixNet",
      label: "Prix Net",
      type: "formatted",
      format: "price",
    },
    { key: "dateCreat", label: "Début", type: "text" },
    { key: "dateFinValid", label: "Fin validité", type: "text" },
    { key: "qteMini", label: "Quantité Min.", type: "number" },
    { key: "refBarem", label: "Barème", type: "text" },
  ];
}
