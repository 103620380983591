/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Base } from "@atoms/text";
import React from "react";

// @ts-ignore
interface TabsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  tabs: { value: string | number; label: JSX.Element | string }[];
  value: string | number;
  onChange: (value: string | number) => void;
  parentClassName?: string;
}

const defaultTabClassName =
  " text-sm cursor-pointer h-12 px-4 flex items-center border-b-solid border-b-2 hover:text-blue-600 transition-colors";
const activeTabClassName = " text-blue-500 border-blue-500 ";
const inactiveTabClassName = " text-zinc-500 border-transparent ";

export default function Tabs(props: TabsProps) {
  return (
    <>
      <div
        className={`overflow-auto flex w-100 border-b border-zinc-200 dark:border-zinc-800 transition-all select-none ${props.className}`}
      >
        {props.tabs.map((tab) => {
          const cl =
            defaultTabClassName +
            (tab.value === props.value
              ? activeTabClassName
              : inactiveTabClassName) +
            props.parentClassName;
          return (
            <Base
              key={tab.value}
              noColor
              className={
                cl + " overflow-hidden whitespace-nowrap text-ellipsis"
              }
              onClick={() => props.onChange(tab.value)}
            >
              {tab.label}
            </Base>
          );
        })}
      </div>
    </>
  );
}
