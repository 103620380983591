import { SearchFormFieldType } from "@molecules/form/types";
import { BookSublist } from "./book";
import { PaperSublist } from "./paper";

export const subListDisplayer = (productType: string, productField: string) => {
  switch (productType) {
    case "D":
      return [];
    case "L":
      if (Reflect.ownKeys(BookSublist).includes(productField)) {
        return BookSublist[
          productField as keyof typeof BookSublist
        ] as SearchFormFieldType[];
      }
      return [];
    case "N":
      return [];
    case "S":
      return [];
    case "P":
      if (Reflect.ownKeys(PaperSublist).includes(productField)) {
        return PaperSublist[
          productField as keyof typeof PaperSublist
        ] as SearchFormFieldType[];
      }
      return [];
    default:
      return [];
  }
};
