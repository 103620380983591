import { Modal, ModalContent } from "@atoms/modal/modal";
import { useReception } from "@features/reception/state/use-reception";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { atom, useRecoilState } from "recoil";

type CommandFournModalType = {
  open: boolean;
  listCde: { numCde: string }[] | undefined;
};

export const CommandFournModalAtom = atom<CommandFournModalType>({
  key: "CommandFournModalAtom",
  default: { open: false, listCde: [] as any[] },
});

export const CommandFournModal = () => {
  const { selectedReception } = useReception();
  const [commandFournModal, setCommandFournModal] = useRecoilState(
    CommandFournModalAtom
  );

  const numCde: Column<any> = {
    title: "No Commande",
    render: (el) => el.numCde,
  };
  // const select: Column<any> = {
  //   render: () => (
  //     <div className="w-full text-right">
  //       <Button>Voir détails</Button>
  //     </div>
  //   ),
  // };

  const handleClose = () => {
    setCommandFournModal({ open: false, listCde: [] });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={commandFournModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent
        title={"Information sur commande:" + selectedReception.numCde}
      >
        <div className="w-full">
          <Table
            emptyTabText={"Aucune commande associée"}
            columns={[numCde]}
            data={commandFournModal.listCde || []}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
