import { useControlledEffect } from "./hooks/use-controlled-effect";
import { BarcodeUtils } from "./barcode";
import { setNativeValue } from "./set-native-value";
import { useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

export const SelectedInputAtom = atom<HTMLInputElement | null>({
  key: "SelectedInputAtom",
  default: null,
});

export const useAutoFocus = () => {
  const [selectedInput, setSelectedInput] = useRecoilState(SelectedInputAtom);
  const location = useLocation();

  const initializeFocus = () => {
    //Petit timer, pour adapter aux temps de chargements
    setTimeout(() => {
      resetSelectedInput();
      const focusedElement = document.querySelector(
        ".to-focus"
      ) as HTMLInputElement;
      setSelectedInput(focusedElement);

      return BarcodeUtils.onScan(
        (code) => {
          if (
            document.activeElement?.tagName?.match(/input|textarea|select/i) &&
            document.activeElement !== selectedInput
          ) {
            return;
          }
          setNativeValue(focusedElement, code.replace(/[^A-Za-z0-9]/g, ""));
          if (focusedElement) focusedElement.focus();
        },
        { event: "keydown" }
      );
    }, 500);
  };

  const resetSelectedInput = () => {
    setSelectedInput(null);
  };

  useControlledEffect(() => {
    initializeFocus();
  }, [location]);

  useControlledEffect(() => {
    resetSelectedInput();
    initializeFocus();
    return () => {
      resetSelectedInput();
    };
  }, []);

  return {
    initializeFocus,
    selectedInput,
  };
};
