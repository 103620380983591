import {
  ArchiveIcon,
  BookOpenIcon,
  ChartBarIcon,
  DatabaseIcon,
  ReplyIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { ROUTES } from "./routes";
import Env from "@config/environment";
import { FaBoxesPacking } from "react-icons/fa6";

export type ModuleType = {
  name: string;
  route: (typeof ROUTES)[keyof typeof ROUTES];
  logo: React.ReactNode;
  color?: string;
  hideModule?: boolean;
  useFrom?: boolean;
};

export const modules: ModuleType[] = [
  {
    name: "Clients",
    logo: <UserCircleIcon className="w-12 h-12" />,
    route: ROUTES.Clients,
  },
  {
    name: "Produits",
    logo: <ShoppingCartIcon className="w-12 h-12" />,
    route: ROUTES.Products,
  },
  {
    name: "Réassort",
    logo: <TruckIcon className="w-12 h-12" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
    //route: ROUTES.FournReassort,
  },
  {
    name: "Réceptions",
    logo: <ArchiveIcon className="w-12 h-12" />,
    //route: ROUTES.Receptions,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },
  {
    name: "Retours",
    logo: <ReplyIcon className="w-12 h-12" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },
  {
    name: "Presse BL/BI",
    logo: <BookOpenIcon className="w-12 h-12" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },
  {
    name: "Inventaire",
    logo: <DatabaseIcon className="w-12 h-12" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },
  {
    name: "Tableau de bord",
    logo: <ChartBarIcon className="w-12 h-12" />,
    route: ROUTES.Dashboard,
    //route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },

  {
    name: "Commande fourn",
    logo: <FaBoxesPacking className="w-12 h-12" />,
    //route: ROUTES.SupplierOrderPage,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
  },

  {
    name: "Préparation commande fournisseur",
    logo: <ChartBarIcon className="w-12 h-12" />,
    route: ROUTES.SupplierCommandOne,
    hideModule: true,
    useFrom: true,
  },

  {
    name: "Préparation retour fournisseur",
    logo: <ChartBarIcon className="w-12 h-12" />,
    route: ROUTES.SupplierRetourOne,
    hideModule: true,
    useFrom: true,
  },
];
