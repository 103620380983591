import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import SelectBoolean from "@atoms/input/input-boolean-select";
import { InputCounter } from "@atoms/input/input-counter";
import Select from "@atoms/input/input-select";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import { Base, BaseBold, Info, Title } from "@atoms/text";
import { ProductTypeShort } from "@features/products/types";
import { usePageNavigation } from "@features/utils/navigation";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";

//Peut être plus tard à factoriser avec la commande --> En attente d'info
export const SupplierRetourMultipleProductPage = () => {
  const { goToPreviousPage } = usePageNavigation();
  const allColumns: Column<ProductTypeShort>[] = [
    {
      title: "Article",
      orderable: true,
      render: (item, { responsive }) =>
        responsive ? (
          <div className="text-right" onClick={() => {}}>
            <Base data-tooltip={item.designation}>{item.designation}</Base>
            <br />
            <Info>{item.id}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {}}
          >
            {item.imageURL && (
              <div className="w-10 print:hidden">
                <BookRender src={item.imageURL} productType={item.modeGest} />
              </div>
            )}
            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={item.designation}
              >
                {item.designation}
              </Base>
              <Info>{item.id}</Info>
            </div>
          </div>
        ),
    },
    {
      title: "Prix PU € HT",
      orderable: true,
      render: (item) => <Base>12€</Base>,
    },
    {
      title: "Quant.",
      orderable: true,
      render: (item) => (
        <div className="flex flex-col justify-center orderItems-center gap-1">
          <div style={{ maxWidth: 128, minWidth: 96 }}>
            <InputCounter
              size={"md"}
              deleteIconOnZero
              value={1}
              onChange={(value) => {}}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Fournisseur",
      orderable: true,
      render: (item) => {
        return (
          <SelectMultiple
            value={[]}
            onChange={(e) => {}}
            onSearch={(e) => {}}
            options={[]}
            selectionLimit={1}
          />
        );
      },
    },
    {
      title: "Mode d'expédition",
      orderable: true,
      render: (item) => {
        return <Select value={[]} onChange={(e) => {}} />;
      },
    },
    {
      title: "Panier",
      orderable: true,
      render: (item) => {
        return <Input></Input>;
      },
    },

    {
      title: "Autorisation",
      orderable: true,
      render: (item) => {
        return <SelectBoolean value={true} onChange={(e) => {}} />;
      },
    },

    {
      title: "Droit",
      orderable: true,
      render: (item) => {
        return <Info>JSP</Info>;
      },
    },
    {
      title: "Motif",
      orderable: true,
      render: (item) => {
        return (
          <SelectMultiple
            value={[]}
            onChange={(e) => {}}
            onSearch={(e) => {}}
            options={[]}
            selectionLimit={1}
          />
        );
      },
    },
  ];
  return (
    <Page>
      <div className="grow flex flex-col gap-8">
        <Title>Retour fournisseur: article multi</Title>
        <Table
          columns={allColumns}
          data={[fakeProductShort, fakeProductShort]}
        />

        <BaseBold className="float-right">
          <Title>TOTAL : 421€</Title>
        </BaseBold>
        <div className="w-full flex justify-evenly">
          <Button
            onClick={(e) => {
              goToPreviousPage();
            }}
            theme="danger"
          >
            Annuler
          </Button>
          <Button icon={(p) => <CheckCircleIcon {...p} />}>Valider</Button>
        </div>
      </div>
    </Page>
  );
};

const fakeProductShort = {
  id: "123",
  designation: "Les Misérables",
  codeTypeProd: "LIVR", // DISQ, LIVR, PRES, PAPE
  libTypeProd: "Livre",
  modeGest: "D",
  resume:
    "Un roman historique qui suit les vies et les interactions de plusieurs personnages.",
  auteur: "Victor Hugo",
  editeur: "Gallimard",
  dateParu: "1862-01-01",
  prixPublic: 29.99,
  prixVente: 24.99,
  qteDispoVente: 10,
  imageURL: "https://example.com/image.jpg",
  libRayon: "Littérature",
  codeRayon: "LIT",
  referLieu: true,
  referGestion: true,
  referBDExt: false,
  row_count: 1,
  qteDispoVenteFutur: 5,
  qteAffecCli: 2,
  qtePrepCdePlusTrans: 1,
  codeEtat: "NEUF",
  codeFournHabit: "456",
  fournHabit: "Hachette",
  codeDisopHabit: "DISPO",
  dispoHabit: "Disponible",
  codeSupp: "789",
  codeColl: "Coll123",
  refsProd: "Ref456",
} as ProductTypeShort;
