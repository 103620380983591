import React, { useEffect, useState } from "react";

export interface PdfViewerProps {
  blobFile: Blob | null;
  iframeSize?: { width: string; height: string };
  iFrameClassname?: string;
  hideOnNull?: boolean;
}

export const PdfViewer = (props: PdfViewerProps) => {
  const [pdfUrl, setPdfUrl] = useState<string>("");

  useEffect(() => {
    if (props.blobFile) {
      const url = URL.createObjectURL(props.blobFile);
      //window.open(url, "_blank", "noopener,noreferrer");
      setPdfUrl(url);
    }
  }, [props.blobFile]);

  return (
    <div
      className={
        props.hideOnNull && props.blobFile === null
          ? "hidden"
          : props.iFrameClassname || ""
      }
    >
      {pdfUrl ? (
        <iframe
          title="TITLE"
          src={pdfUrl}
          width={props.iframeSize?.width || "90%"}
          height={props.iframeSize?.height || "90%"}
        />
      ) : (
        "Chargement..."
      )}
    </div>
  );
};

export default PdfViewer;
