import { Page } from "@atoms/layout/page";
import { Section, Title } from "@atoms/text";
import { ShortcutMapping } from "@features/settings/shortcuts/mapping";
import { Table } from "@molecules/table";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const SettingsShortcutsPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Title>{t("settings.shortcuts.title", "Raccourcis")}</Title>
      <br />
      {Object.keys(ShortcutMapping).map((s) => (
        <div className="mb-8">
          <Section>
            {t(`settings.shortcuts.${s}.title`, _.upperFirst(s))}
          </Section>
          <Table
            data={Object.keys((ShortcutMapping as any)[s] as any)}
            columns={[
              {
                title: "Action",
                thClassName: "w-1/2",
                render: (row) =>
                  t(
                    `settings.shortcuts.${s}.${row}`,
                    _.upperFirst(row.replace(/_/g, " "))
                  ),
              },
              {
                title: "Raccourci",
                render: (row) => ((ShortcutMapping as any)[s] as any)[row],
              },
            ]}
          />
        </div>
      ))}
    </Page>
  );
};
