import { AuthJWT } from "@features/general/auth/jwt";
import { fetchServer } from "@features/utils/fetch-server";

export class AccountApiClient {
  static updatePassword = async (oldPwd: string, pwd: string) => {
    const data = await fetchServer("/user/authenticate/password", {
      method: "PUT",
      body: JSON.stringify({
        id: AuthJWT.userId,
        oldPwd: oldPwd,
        pwd: pwd,
      }),
      reloadOn401: false,
    });
    if (data.status === 204) {
      return true;
    }
    return false;
  };
}
