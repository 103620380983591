import { CustomerMode } from "@features/customer/state/store";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useProduct } from "@features/products/state/use-product";
import { Paper } from "@features/products/types";
import { SearchFormFieldType } from "@molecules/form/types";
import { CatalogueModalAtom } from "@views/client/products/product/sublists/catalogues";
import { ConditionAchatModalAtom } from "@views/client/products/product/sublists/conditions-achat";
import { EcoPartModalAtom } from "@views/client/products/product/sublists/eco-participation";
import { PricesModalAtom } from "@views/client/products/product/sublists/prices";
import { PricesDegModalAtom } from "@views/client/products/product/sublists/prices-degressif";
import { ProdEanModalAtom } from "@views/client/products/product/sublists/prod-ean";
import { SuppliersModalAtom } from "@views/client/products/product/sublists/suppliers";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const PaperForm = (product: Paper | null) => {
  const { ean } = useParams<{ ean: string }>();
  const customerMode = useRecoilValue(CustomerMode);
  const { current } = useShopLocations();
  const { getFieldSuggestions } = useProduct(ean || "");
  const openSuppliersModal = useSetRecoilState(SuppliersModalAtom);
  const openPricesModal = useSetRecoilState(PricesModalAtom);
  const openCatalogueModal = useSetRecoilState(CatalogueModalAtom);
  const openEcoPartModal = useSetRecoilState(EcoPartModalAtom);
  const openPricesDegModal = useSetRecoilState(PricesDegModalAtom);
  const openConditionAchatModal = useSetRecoilState(ConditionAchatModalAtom);
  const openProdEanModal = useSetRecoilState(ProdEanModalAtom);

  const general: SearchFormFieldType[] = [
    {
      key: "fourn",
      label: "Fabricant",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "libMarq",
      label: "Marque",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "prixAchatHT1",
      label: "Prix d'achat HT1",
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "libRayon",
      label: t("products.livre.libRayon"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "idBD",
      label: "ID BD",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "ean13",
      label: "EAN",
      type: "text",
      alwaysVisible: true,
    },
  ];

  const identification: SearchFormFieldType[] = [
    {
      key: "refFabComp",
      label: "Référence fabricant",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "matchCode",
      label: t("products.livre.identification.matchCode"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "refInterne",
      label: t("products.livre.identification.refInterne"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "commInt",
      label: t("products.livre.identification.commInterne"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "ean13Preced",
      label: t("products.livre.identification.eanPrec"),
      type: "scan",
    },
    {
      key: "ean13Regrp",
      label: t("products.livre.identification.eanRegroup"),
      type: "scan",
    },
    {
      key: "ean13Subst",
      label: t("products.livre.identification.eanSubst"),
      type: "scan",
    },
    {
      key: "ean13Detail",
      label: t("products.livre.identification.eanDetail"),
      type: "scan",
    },
    {
      key: "ean13Neuf",
      label: t("products.livre.identification.eanNeuf"),
      type: "scan",
    },
    {
      key: "listProdEAN",
      label: "Références Multiples du Produit ",
      type: "modal",
      alwaysVisible: true,
      render: () => `(${(product?.listProdEan || []).length})`,
      onClick: ({
        readonly,
        values,
      }: {
        readonly?: boolean;
        values: Paper;
      }) => {
        openProdEanModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listProdEan || [],
        });
      },
    },
    {
      key: "idBD",
      label: "Id BD",
      type: "text",
      alwaysVisible: true,
    },
  ];

  const classification: SearchFormFieldType[] = [
    {
      key: "libFamil",
      label: "Famille",
      type: "select",
    },
    {
      key: "libSousFamil",
      label: "Sous famille",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "libSegment",
      label: "Segment",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "pays",
      label: t("products.livre.classification.pays"),
      type: "text",
      alwaysVisible: true,
      options: [
        { label: "FR - FRANCE", value: "FR - FRANCE" },
        { label: "JP - JAPON", value: "JP - JAPON" },
      ], // Encore à déterminer
    },
  ];

  const venteGeneral: SearchFormFieldType[] = [
    {
      key: "Coefficient",
      label: "Coefficient",
      type: "select",
    },
    {
      key: "prixVenteCons",
      label: "PV conseillé",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "prixVenteHT1",
      label: t("products.livre.vente.pvHT1"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "tauxTaxe1",
      label: t("products.livre.vente.tva1"),
      type: "formatted",
      format: "percentage",
    },
    {
      key: "prixVenteHT2",
      label: t("products.livre.vente.pvHT2"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "tauxTaxe2",
      label: t("products.livre.vente.tva2"),
      type: "formatted",
      format: "percentage",
    },
    /* {
      key: "gerePrixAchat",
      label: t("products.livre.vente.gererprixAchat"),
      type: "text",
      alwaysVisible:true,
    }, */
  ];

  const venteCanal: SearchFormFieldType[] = [
    {
      key: "prixVenteRef",
      label: t("products.livre.vente.prixRef"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "prixVente",
      label: t("products.livre.vente.prixVente"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "refVente",
      label: t("products.livre.vente.refVente"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "promo",
      label: t("products.livre.vente.promotion"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "dateDebutPromo",
      label: t("products.livre.vente.debutPromotion"),
      type: "date",
    },
    {
      key: "dateFinPromo",
      label: t("products.livre.vente.finPromotion"),
      type: "date",
    },
    {
      key: "actif",
      label: t("products.livre.vente.actif"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "datePremVente",
      label: t("products.livre.vente.premVente"),
      type: "date",
    },
    {
      key: "dateDernVente",
      label: t("products.livre.vente.dernVente"),
      type: "date",
    },
    {
      key: "plcv.qteVente",
      label: t("products.livre.vente.qtVendue"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "marge",
      label: t("products.livre.vente.marge"),
      type: "formatted",
      format: "percentage",
      hidden: customerMode,
    },
    {
      key: "priveVenteHT",
      label: t("products.livre.vente.pvHT"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "listTarif",
      label: "Tarifs",
      type: "modal",
      alwaysVisible: true,
      render: () => `(${(product?.listTarif || []).length})`,
      onClick: ({ readonly }: { readonly?: boolean; values: Paper }) => {
        openPricesModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listTarif || [],
        });
      },
    },
    {
      key: "listTarifDegressif",
      label: "Tarifs dégressifs",
      type: "modal",
      alwaysVisible: true,
      render: () => `(${(product?.listTarifDegressif || []).length})`,
      onClick: ({ readonly }: { readonly?: boolean; values: Paper }) => {
        openPricesDegModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listTarifDegressif || [],
        });
      },
    },
    {
      key: "listTaxeEco",
      label: "Eco-participation",
      type: "modal",
      alwaysVisible: true,
      render: () => `(${(product?.listTaxeEco || []).length})`,
      onClick: ({ readonly }: { readonly?: boolean; values: Paper }) => {
        openEcoPartModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listTaxeEco || [],
        });
      },
    },
    {
      key: "listCatalogue",
      label: "Catalogue",
      type: "modal",
      alwaysVisible: true,
      render: () => `(${(product?.listCatalogue || []).length})`,
      onClick: ({ readonly }: { readonly?: boolean; values: Paper }) => {
        openCatalogueModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listCatalogue || [],
        });
      },
    },
  ];

  const approvisionnement: SearchFormFieldType[] = [
    {
      key: "datePreco",
      label: t("products.livre.approvisionnement.precommandes"),
      type: "date",
    },
    {
      key: "codeEtat",
      label: t("products.livre.approvisionnement.etat"),
      type: "select",
      options: [
        { label: "Obligatoire", value: "Obligatoire" },
        { label: "Supprimé", value: "Supprimé" },
      ],
      render: (value) => {
        switch (value) {
          case "S":
            return "Supprimé";
          case "O":
            return "Obligatoire";
          default:
            return value;
        }
      },
    },
    {
      key: "emplac",
      label: t("products.livre.approvisionnement.emplacement"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "qteStockMini",
      label: t("products.livre.approvisionnement.min"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "qteStockMaxi",
      label: t("products.livre.approvisionnement.max"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "fourn",
      label: t("products.livre.approvisionnement.fournRef"),
      type: "text",
      alwaysVisible: true,
      options: async (query) => {
        const result = await getFieldSuggestions(query);
        return result as [];
      },
    },
    {
      key: "dispo",
      label: t("products.livre.approvisionnement.dispoFref"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "fournHabit",
      label: t("products.livre.approvisionnement.fournHabit"),
      type: "text",
      alwaysVisible: true,
      options: async (query) => {
        const result = await getFieldSuggestions(query);
        return result as [];
      },
    },
    {
      key: "dispoHabit",
      label: t("products.livre.approvisionnement.dispoFHabit"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "internetPres",
      label: t("products.livre.approvisionnement.presentInternet"),
      type: "boolean",
    },
    {
      key: "internetRejet",
      label: t("products.livre.approvisionnement.rejetInternet"),
      type: "boolean",
    },
    {
      key: "internetRetir",
      label: t("products.livre.approvisionnement.retireInternet"),
      type: "boolean",
    },
    {
      key: "conditionAchat",
      label: t("products.livre.approvisionnement.conditionsAchat"),
      type: "modal",
      alwaysVisible: true,
      hidden: customerMode,
      render: () => `(${(product?.listConditionAchat || []).length})`,
      onClick: ({
        readonly,
        values,
      }: {
        readonly?: boolean;
        values: Paper;
      }) => {
        openConditionAchatModal({
          type: "paper",
          id: product?.id || "",
          readonly: !!readonly,
          data: product?.listConditionAchat || [],
        });
      },
    },
    {
      key: "fourn",
      label: t("products.livre.approvisionnement.fournisseurs"),
      type: "modal",
      render: (_value, values: Paper) =>
        values.listFourn
          ?.map((a) => a.raisSocial + ` (${a.codeFourn})`)
          .join(", "),
      onClick: ({ readonly, values }: { readonly?: boolean; values: Paper }) =>
        openSuppliersModal({
          type: "paper",
          id: values.id,
          readonly: !!readonly,
          data: values.listFourn,
        }),
    },
  ];

  const stock: SearchFormFieldType[] = [
    {
      key: "epais",
      label: t("products.livre.stock.epaisseur"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "haut",
      label: t("products.livre.stock.hauteur"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "larg",
      label: t("products.livre.stock.largeur"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "poids",
      label: t("products.livre.stock.poids"),
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "dateDernCde",
      label: t("products.livre.stock.dernCommande"),
      type: "date",
    },
    {
      key: "dateDernMvt",
      label: t("products.livre.stock.dernMouvement"),
      type: "date",
    },
    {
      key: "dateDernRecep",
      label: t("products.livre.stock.dernReception"),
      type: "date",
    },
    {
      key: "dateDernRet",
      label: t("products.livre.stock.dernRetour"),
      type: "date",
    },
    {
      key: "datePremEntre",
      label: t("products.livre.stock.premEntree"),
      type: "date",
    },
    {
      key: "dateDernRupt",
      label: t("products.livre.stock.dernRupture"),
      type: "date",
    },
    {
      key: "dateDernInven",
      label: t("products.livre.stock.dernInventaire"),
      type: "date",
    },
    {
      key: "dateEntreeMP",
      label: t("products.livre.stock.entreMoyenPond"),
      type: "date",
    },
    {
      key: "dateDernRe",
      label: t("products.livre.stock.dernPaNet"),
      type: "date",
    },
    {
      key: "dernPaNetHT",
      label: t("products.livre.stock.dernPaNetNet"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
    },
    {
      key: "dernPaNetNetHT",
      label: "Dern PA net net H",
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "pampNetHT",
      label: t("products.livre.stock.pampNet"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "pampNetNetHT",
      label: t("products.livre.stock.pampNetNet"),
      type: "formatted",
      format: "price",
      currencySymb: current?.devisSymb,
      currencyNbDecim: current?.devisNbDecim,
      hidden: customerMode,
    },
    {
      key: "numZone",
      label: "Zone",
      type: "text",
      alwaysVisible: true,
      hidden: customerMode,
    },
  ];

  return {
    general,
    identification,
    classification,
    venteGeneral,
    venteCanal,
    approvisionnement,
    stock,
  };
};
