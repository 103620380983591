import { useLocation, useNavigate } from "react-router-dom";

export const usePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;

  const goToPreviousPage = () => {
    navigate(from);
  };

  return { goToPreviousPage };
};
