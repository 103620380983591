import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import { FormInput } from "@molecules/form/fields";
import { FormReadonly } from "@molecules/form/readonly";
import { SearchFormFieldType } from "@molecules/form/types";
import { Table } from "@molecules/table";
import _ from "lodash";
import { useState } from "react";
import { RecoilState, useRecoilState } from "recoil";

export const SublistModal = (props: {
  schema: SearchFormFieldType[];
  atom: RecoilState<{
    type: string;
    id: string;
    data: any[];
    readonly: boolean;
  }>;
}) => {
  const [modal, setModal] = useRecoilState(props.atom);
  return (
    <Modal
      className="w-full sm:!w-[90%] !max-w-full"
      open={!!modal.id}
      onClose={() =>
        setModal({ id: "", type: "", readonly: modal.readonly, data: [] })
      }
      closable={modal.readonly}
    >
      {!!modal.id && (
        <ListModalContent
          id={modal.id}
          type={modal.type}
          readonly={modal.readonly}
          data={modal.data}
          schema={props.schema}
        />
      )}
    </Modal>
  );
};

const ListModalContent = (props: {
  id: string;
  type: string;
  readonly: boolean;
  data: any[];
  title?: string;
  schema: any[];
}) => {
  const [data, setData] = useState(props.data);
  const isReadonly = props.readonly;
  const schema = props.schema;
  const [order, setOrder] = useState<{
    order?: "ASC" | "DESC" | undefined;
    orderBy?: number;
  } | null>({ order: "ASC", orderBy: 0 });

  const orderedData = _.orderBy(data, (a) => {
    const key = Object.values(schema)[order?.orderBy as any].key;
    return a[key];
  });

  const finalData =
    order?.order === "DESC" ? _.reverse(orderedData) : orderedData;

  return (
    <ModalContent title={props.title || "Liste"}>
      <div className="bg-slate-50 px-2 -mx-2 border rounded-md">
        <Table
          name={props.title}
          className="mb-4"
          onFetchExportData={async () => finalData}
          data={finalData}
          rowIndex="id"
          onRequestData={async ({ order, orderBy }) => {
            if (order && orderBy) {
              setOrder({ order, orderBy });
            } else {
              setOrder({ order: "ASC", orderBy: 0 });
            }
          }}
          onSelect={
            isReadonly
              ? undefined
              : [
                  {
                    label: "Delete",
                    icon: (p) => <TrashIcon {...p} />,
                    callback: (row) => {},
                  },
                ]
          }
          columns={[
            ...schema.map((f) => ({
              title: f.label,
              orderable: true,
              render: (row: any) => (
                <>
                  {isReadonly ? (
                    <FormReadonly
                      value={row[f.key]}
                      {...(_.omit(f, "label") as any)}
                      size="md"
                    />
                  ) : (
                    <FormInput
                      value={row[f.key]}
                      {...(_.omit(f, "label") as any)}
                      onChange={() => {}}
                      placeholder={f.label}
                      size="md"
                    />
                  )}
                </>
              ),
            })),
          ]}
        />
        {!isReadonly && (
          <div className="mb-3">
            <Button
              onClick={() => {
                setData([...data, { id: data.length + 1 }]);
              }}
              theme="outlined"
              icon={(p) => <PlusCircleIcon {...p} />}
            >
              Ajouter une ligne
            </Button>
          </div>
        )}
      </div>

      {!isReadonly && (
        <div className="mt-4">
          <Button theme="danger">Annuler</Button>
          <Button theme="primary" className="float-right">
            Sauvegarder
          </Button>
        </div>
      )}
    </ModalContent>
  );
};
